import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Netlog } from './netlog.class';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetlogService {

  baseurl: string = environment.CalllogAPIurl + "/api/netlogs";
  constructor(
    private http: HttpClient
  ) { }

  create(netlog: Netlog): Observable<Netlog> {
    return this.http.post(`${this.baseurl}`, netlog, { headers: {['XApiKey']: `${sessionStorage.getItem('XApiKey')}`}}) as Observable<Netlog>;
  }
  change(netlog: Netlog): Observable<any> {
    return this.http.put(`${this.baseurl}/${netlog.id}`, netlog, { headers: {['XApiKey']: `${sessionStorage.getItem('XApiKey')}`}}) as Observable<any>;
  }
}
