<app-menu></app-menu>
<app-time-out-modal></app-time-out-modal>

<ejs-toast id="toast" #toastMessageBox [position]='toastMessagePosition'>
    <ng-template #title>
        <div>{{toastMessageTitle}}</div>
    </ng-template>
    <ng-template #content>
        <div>{{toastMessageContent}}</div>
    </ng-template>
</ejs-toast>

<h2 style="margin-left: 10px;">My Groups</h2>

<h4 *ngIf="mygroups.length <= 0" style="margin-left: 10px; color: red;">---- No Groups To Display! ----</h4>
<table class="group-table" *ngIf="mygroups.length > 0">
    <tr>
        <th>Group Name:</th>
        <th>Owner Name:</th>
        <th>Date Created:</th>
        <th>Action:</th>
    </tr>
    <ng-template ngFor let-g [ngForOf]="mygroups" let-i="index">
        <tr>
            <td>{{g.groupName}}</td>
            <td>{{g.name}}</td>
            <td>{{g.dateCreated | date: 'short'}}</td>
            <td><button class="btn btn-sm btn-danger" (click)="deleteGroupReference(g)">Delete Group</button></td>
        </tr>
    </ng-template>
</table>
