import { Component } from '@angular/core';
import * as cat from 'src/assets/js/cat/cat.js'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent {

  ngAfterViewInit() {
    cat.initcat();
  }

  ngOnInit() {
    //cat.initcat();
  }
}
