import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class BackupService {

    baseurl: string = environment.CalllogAPIurl + '/api/backup';

    constructor(
        private http: HttpClient,
    ) { }

    exportBackup(id: string): Observable<any> {
        return this.http.get(`${this.baseurl}/export/backup/${id}`, { responseType: 'blob', headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<any>;
    }
    restoreFromBackup(id: string, file: File, rh: boolean, ru: boolean, rg: boolean, rn: boolean): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', file);
        return this.http.post(`${this.baseurl}/import/restore-from-backup?userId=${id}&rh=${rh}&ru=${ru}&rg=${rg}&rn=${rn}`, formData, { headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<any>;
    }

}

