export const SectionAbbriviations = {
    1: {
        name: "Call Area 1",
        sections: [
            { full: "Connecticut", abb: "CT" },
            { full: "Eastern", abb: "EMA" },
            { full: "Massachusetts", abb: "MA" },
            { full: "Maine", abb: "ME" },
            { full: "New Hampshire", abb: "NH" },
            { full: "Rhode Island", abb: "RI" },
            { full: "Vermont", abb: "VT" },
            { full: "Western Massachusetts", abb: "WMA" },
        ]
    },
    2: {
        name: "Call Area 2",
        sections: [
            { full: "Eastern New York", abb: "ENY" },
            { full: "Northern New York", abb: "NNY" },
            { full: "NYC / Long Island", abb: "NLI" },
            { full: "Southern New Jersey", abb: "SNJ" },
            { full: "Northern New Jersey", abb: "NNJ" },
            { full: "Western New York", abb: "WNY" }
        ]
    },
    3: {
        name: "Call Area 3",
        sections: [
            { full: "Delaware", abb: "DE" },
            { full: "Western Pennsylvania", abb: "WPA" },
            { full: "Maryland-DC", abb: "DC" },
            { full: "Eastern Pennsylvania", abb: "EPA" },
        ]
    },
    4: {
        name: "Call Area 4",
        sections: [
            { full: "Alabama", abb: "AL" },
            { full: "Georgia", abb: "FA" },
            { full: "Kentucky", abb: "KY" },
            { full: "North Carolina", abb: "NC" },
            { full: "Northern Florida", abb: "NFL" },
            { full: "South Carolina", abb: "SC" },
            { full: "Southern Florida", abb: "SFL" },
            { full: "Tennessee", abb: "TN" },
            { full: "West Central Florida", abb: "WCF" },
            { full: "Virginia", abb: "VA" },
            { full: "Puerto Rico", abb: "PR" },
            { full: "US Virgin Islands", abb: "VI" },
        ]
    },
    5: {
        name: "Call Area 5",
        sections: [
            { full: "Arkansas", abb: "AR" },
            { full: "Louisiana", abb: "LA" },
            { full: "New Mexico", abb: "NM" },
            { full: "Mississippi", abb: "MS" },
            { full: "North Texas", abb: "NTX" },
            { full: "Oklahoma", abb: "OK" },
            { full: "South Texas", abb: "STX" },
            { full: "West Texas", abb: "WTX" },
        ]
    },
    6: {
        name: "Call Area 6",
        sections: [
            { full: "East Bay", abb: "EB" },
            { full: "Los Angeles", abb: "LAX" },
            { full: "Orange", abb: "ORG" },
            { full: "Santa Barbara", abb: "SB" },
            { full: "Santa Clara Valley", abb: "SCV" },
            { full: "San Diego", abb: "SDG" },
            { full: "San Francisco", abb: "SF" },
            { full: "San Joaquin Valley", abb: "SJV" },
            { full: "Sacramento Valley", abb: "SV" },
            { full: "Pacific", abb: "PAC" },
        ]
    },
    7: {
        name: "Call Area 7",
        sections: [
            { full: "Alaska", abb: "AK" },
            { full: "Arizona", abb: "AZ" },
            { full: "Eastern Washington", abb: "EWA" },
            { full: "Idaho", abb: "ID" },
            { full: "Montana", abb: "MT" },
            { full: "Nevada", abb: "NV" },
            { full: "Oregon", abb: "OR" },
            { full: "Utah", abb: "UT" },
            { full: "Western Washington", abb: "WWA" },
            { full: "Wyoming" , abb: "WY" },
        ]
    },
    8: {
        name: "Call Area 8",
        sections: [
            { full: "Michigan", abb: "MI" },
            { full: "Ohio", abb: "OH" },
            { full: "West Virgina", abb: "WV" },
        ]
    },
    9: {
        name: "Call Area 9",
        sections: [
            { full: "Indiana", abb: "IN" },
            { full: "Illinois", abb: "IL" },
            { full: "Wisconsin", abb: "WI" },
        ]
    },
    0: {
        name: "Call Area 0",
        sections: [
            { full: "Colorado", abb: "CO" },
            { full: "Iowa", abb: "IA" },
            { full: "Kansas", abb: "KS" },
            { full: "Minnesota", abb: "MN" },
            { full: "Missouri", abb: "MO" },
            { full: "Nebraska", abb: "NE" },
            { full: "North Dakota", abb: "ND" },
            { full: "South Dakota", abb: "SD" },
        ]
    },
    "Canada": {
        name: "Canada",
        sections: [
            { full: "Alberta", abb: "AB" },
            { full: "British Columbia", abb: "BC" },
            { full: "Golden Horseshoe*", abb: "GH (formerly GTA)" },
            { full: "Manitoba", abb: "MB" },
            { full: "New Brunswick*", abb: "NB" },
            { full: "Newfoundland/Labrador", abb: "NL" },
            { full: "Nova Scotia*", abb: "NS" },
            { full: "Ontario East", abb: "ONE" },
            { full: "Ontario North", abb: "ONN" },
            { full: "Ontario South", abb: "ONS" },
            { full: "Prince Edward Island", abb: "PE" },
            { full: "Saskatchewan", abb: "SK" },
            { full: "Territories*", abb: "TER" },
            { full: "Quebec", abb: "QC" },
        ]
    }
}