import { Component, ElementRef, EventEmitter, HostListener, Output, Renderer2, ViewChild } from '@angular/core';
import { Menu } from '../menu.class';
import { UserService } from 'src/app/user/services/user.service';
import { AmateurSearchService } from 'src/app/amateur/amateur-search.service';
import { Router } from '@angular/router';
import { LoggerService } from 'src/app/user/services/logger.service';
import { NewInvitation } from 'src/app/user/requests.class';
import { GroupInvitation } from 'src/app/user/groupInvitation.class';
import { GroupSerive } from 'src/app/user/services/groups.service';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent {
  searchCallsign: string = "";
  isAdmin: boolean = true;
  username: string = "";
  userId: string = "";
  isLoggedIn: boolean = false;
  isSubAdmin: boolean = false;
  isGroupAdmin: boolean = false;
  notificationCount: string = "";
  invites: GroupInvitation[] = [];
  @ViewChild('notification') el!: ElementRef;

  @Output() onHamlogTask = new EventEmitter();
  
  constructor(
    private usrsvc: UserService,
    private fccsvc: AmateurSearchService,
    private router: Router,
    private sys: LoggerService,
    private _groupSvc: GroupSerive,

  ){}
  hamlogUpdateRequest() {
    this.onHamlogTask.emit();
  }
  logout(): void {
    this.usrsvc.logout();
  }
  search(): void {
    this.fccsvc.callsignSearch = this.searchCallsign;
    this.router.navigateByUrl(`/amateur/search/${this.searchCallsign}`);
  }
  goToLogging(){
    this.router.navigateByUrl(this.sys.logURL + this.userId)
    //this.router.navigateByUrl(`/logging/contest/fieldday/newlog/${this.userId}`)
  }

  notificationClick() {
    $('.box').toggleClass("visibleBox");
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    if(this.el != undefined){
      if (!this.el.nativeElement.contains(event.target)) {
        $('.box').removeClass("visibleBox");
      }
    }
  }


  refresh() {
    if(this.isLoggedIn){
      this._groupSvc.getRecipiantInvites(this.sys.userId, false, false).subscribe({
        next: (res) => {
          this.invites = res;
          if(this.invites.length > 0){
            this.notificationCount = this.invites.length.toString();
          }
          else {
            this.notificationCount = "";
          }
        },
        error: (err) => {
          console.error(err);
        }
      });
    }
  }
  ngAfterViewInit() {

  }
  renew() {
    this.isAdmin = this.sys.isAdmin;
    this.isSubAdmin = this.sys.userCtrlLevel == "SubAdmin" ? true : false;
    this.isGroupAdmin = this.sys.isGroupAdmin;
    this.userId = this.sys.userId;
    this.isLoggedIn = this.sys.isLoggedin;
    var ssUserName = this.sys.username;
    this.username = this.isLoggedIn ? "Logout " + ssUserName : "Login";
    this.refresh();
  }
  ngOnInit(): void {
    this.renew();
  }
}
