import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Hamlog } from './hamlog.class';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UniqueNetLog } from './uniqueNetlog.class';

@Injectable({
  providedIn: 'root'
})
export class HamlogService {
  // baseurl: string = "http://localhost:5000/api/hamlogs";
   baseurl: string = environment.CalllogAPIurl + "/api/hamlogs";

  constructor(
    private http: HttpClient
  ) { }

  getByCallUser(callsign: string, userId: string): Observable<Hamlog[]> {
    return this.http.get(`${this.baseurl}/${callsign}/${userId}`, { headers: {['XApiKey']: `${sessionStorage.getItem('XApiKey')}`}}) as Observable<Hamlog[]>;
  }
  netRtUniqueIdList(userId: string): Observable<UniqueNetLog[]> {
    return this.http.get(`${this.baseurl}/list/uniquenetrtids/${userId}`, { headers: {['XApiKey']: `${sessionStorage.getItem('XApiKey')}`}}) as Observable<UniqueNetLog[]>;
  }
  get(id: string): Observable<Hamlog> {
    return this.http.get(`${this.baseurl}/${id}`, { headers: {['XApiKey']: `${sessionStorage.getItem('XApiKey')}`}}) as Observable<Hamlog>;
  }
  create(hamlog: Hamlog): Observable<Hamlog> {
    return this.http.post(`${this.baseurl}`, hamlog, { headers: {['XApiKey']: `${sessionStorage.getItem('XApiKey')}`, ['instanceId']: `${localStorage.getItem('instanceId')}`}}) as Observable<Hamlog>;
  }
  change(hamlog: Hamlog): Observable<any> {
    return this.http.put(`${this.baseurl}/${hamlog.id}`, hamlog, { headers: {['XApiKey']: `${sessionStorage.getItem('XApiKey')}`}}) as Observable<any>;
  }
  changeList(hamlogs: Hamlog[]): Observable<any> {
    return this.http.post(`${this.baseurl}/update/multi`, hamlogs, { headers: {['XApiKey']: `${sessionStorage.getItem('XApiKey')}`}}) as Observable<any>;
  }
  remove(id: string): Observable<any> {
    return this.http.delete(`${this.baseurl}/${id}`, { headers: {['XApiKey']: `${sessionStorage.getItem('XApiKey')}`}}) as Observable<any>;
  }
  removeList(ids: string[]): Observable<any> {
    return this.http.post(`${this.baseurl}/multi`, ids, { headers: {['XApiKey']: `${sessionStorage.getItem('XApiKey')}`}}) as Observable<any>;
  }
}
