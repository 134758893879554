<app-menu></app-menu>
<app-time-out-modal></app-time-out-modal>

<ejs-toast id="toast" #toastMessageBox [position]='toastMessagePosition'>
    <ng-template #title>
        <div>{{toastMessageTitle}}</div>
    </ng-template>
    <ng-template #content>
        <div>{{toastMessageContent}}</div>
    </ng-template>
</ejs-toast>

<h2 style="margin-left: 10px;">Add or Edit News Items</h2>
<table>
    <tr>
        <th></th>
        <th>Title</th>
        <th>Description</th>
        <th>Date Start</th>
        <th>Date End</th>
    </tr>
    <ng-template ngFor let-x [ngForOf]="newsItems" let-i="index">
        <tr>
            <td>
                <button class="btn btn-danger delBtn" (click)="delete(x.id)">Delete</button>
            </td>
            <td>
                <ejs-textbox [(ngModel)]="x.title" placeholder="Title"></ejs-textbox>
            </td>
            <td>
                <ejs-textbox [(ngModel)]="x.description" placeholder="Description"></ejs-textbox>
            </td>
            <td>
                <ejs-datepicker [(ngModel)]="x.dateStart" strictMode="true" placeholder="Date Start"></ejs-datepicker>
            </td>
            <td>
                <ejs-datepicker [(ngModel)]="x.dateEnd" strictMode="true" placeholder="Date End"></ejs-datepicker>
            </td>
        </tr>
        <tr>
            <td><ejs-input [(value)]="x.fontColor" ejs-colorpicker type="color" id="colorPickerElement" mode="Palette" /></td>
            <td colspan="100%">
                <ejs-textbox [(ngModel)]="x.news" placeholder="News"></ejs-textbox>
            </td>
        </tr>
        <tr>
            <td colspan="100%" height="20px"></td>
        </tr>
    </ng-template>
</table>

<button class="btn btn-primary formBtn" (click)="addNewNewsItem()">Add</button>
<button class="btn btn-success formBtn" (click)="save()">Save</button>

