import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WeatherService {

  baseurl: string = "https://api.weather.gov"

  constructor(
    private http: HttpClient
  ) { }

  getNoaaGrid(lat, lon): Observable<any> {
    return this.http.get(`${this.baseurl}/points/${lat},${lon}`) as Observable<any>;
  }
  getForecast(url): Observable<any> {
    return this.http.get(url) as Observable<any>;
  }


}
