<app-menu></app-menu>
<div id="content">
  <h1>W8ZJT HOME PAGE</h1>
  <h2>Amateur Radio Logging Application</h2>
  <div style="width:100%; margin:auto;">
    <div class="row carousel-row">
      <div class="col-md-1">
        <button class="control-button" type="button" data-bs-target="#images-carousel" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
      </div>
      <div class="col-md-10">
        <div id="images-carousel" class="carousel carousel-dark slide" data-bs-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src="assets/screenshots/main.png" class="d-block w-100 calllog-img" alt="...">
              <div>
                <h5 class="mt-5">Main Application View</h5>
                <p>Entering logs in your logbook has never been eaiser.</p>
              </div>
            </div>
            <div class="carousel-item">
              <img src="assets/screenshots/editmodal.png" class="d-block w-100 calllog-img" alt="...">
              <div>
                <h5 class="mt-5">Quick Edit</h5>
                <p>Made a mistake? Making changes to your logbook is a breeze.</p>
              </div>
            </div>
            <div class="carousel-item">
              <img src="assets/screenshots/dx.png" class="d-block w-100 calllog-img" alt="...">
              <div>
                <h5 class="mt-5">DX-Spotting interface</h5>
                <p>Customizable, filterable, reliable DX-Spot network will get you the contacts you've been looking for.
                </p>
              </div>
            </div>
          </div>
          <div class="carousel-indicators" style="position: relative;">
            <button type="button" data-bs-target="#images-carousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#images-carousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#images-carousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
          </div>
        </div>
      </div>
      <div class="col-md-1">
        <button class="control-button" type="button" data-bs-target="#images-carousel" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
    </div>

<script>
    $('.carousel').carousel({
  interval: 2000
})
</script>