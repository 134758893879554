import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { FileDto } from "../filedto.class";

@Injectable({
    providedIn: 'root'
})

export class FileStorageService {

    baseurl: string = environment.CalllogAPIurl + '/api/filestorage';

    constructor(
        private http: HttpClient,
    ) { }

    list(userId: string): Observable<FileDto[]> {
        return this.http.get(`${this.baseurl}/user/list/${userId}`, { headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<FileDto[]>;
    }
    publist(): Observable<FileDto[]> {
        return this.http.get(`${this.baseurl}/public/list`, { headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<FileDto[]>;
    }
    getFile(file: FileDto, userId: string): Observable<any>{
        file.userId = userId;
        return this.http.post(`${this.baseurl}/get/file`, file, { responseType: 'blob', headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<any>;
    }
    deleteFile(file: FileDto, userId: string): Observable<any>{
        file.userId = userId;
        return this.http.post(`${this.baseurl}/delete/file`, file, { headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<any>;
    }
    uploadFile(file: File, userId: string, type: string): Observable<any>{
        const formData: FormData = new FormData();
        formData.append('file', file);
        return this.http.post(`${this.baseurl}/upload/file/${type}?userId=${userId}`, formData, { headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<any>;
    }

}
