import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { RenewLoginCredentials } from "../logincredentials.class";

@Injectable({
    providedIn: 'root'
})

export class SessionService {

    baseurl: string = environment.CalllogAPIurl + '/api/activesession';

    constructor(
        private http: HttpClient,
    ) { }

    killSession(data: RenewLoginCredentials): Observable<any> {
        return this.http.post(`${this.baseurl}/removesession`, data, { headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<any>;
    }


}