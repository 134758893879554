import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { UserSetting } from '../userSetting.class';

@Injectable({
  providedIn: 'root'
})
export class UserSettingService {
  baseurl: string = environment.CalllogAPIurl + '/api/usersettings';
  constructor(
    private http: HttpClient,
  ) { }

  create(userSetting: UserSetting): Observable<UserSetting> {
    return this.http.post(`${this.baseurl}`, userSetting, { headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<UserSetting>;
  }
  get(id: string): Observable<UserSetting> {
    return this.http.get(`${this.baseurl}/${id}`, { headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<UserSetting>;
  }
  edit(id: string ,userSetting: UserSetting): Observable<any>{
    return this.http.put(`${this.baseurl}/${id}`, userSetting, { headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<any>
  }
  remove(id: string): Observable<any>{
    return this.http.delete(`${this.baseurl}/${id}`, { headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<any>
  }
}
