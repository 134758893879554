import { Component } from '@angular/core';

@Component({
  selector: 'app-changes',
  templateUrl: './changes.component.html',
  styleUrl: './changes.component.css'
})
export class ChangesComponent {

}
