<app-menu></app-menu>
<app-time-out-modal></app-time-out-modal>

<ejs-toast id="toast" #toastMessageBox [position]='toastMessagePosition'>
    <ng-template #title>
        <div>{{toastMessageTitle}}</div>
    </ng-template>
    <ng-template #content>
        <div>{{toastMessageContent}}</div>
    </ng-template>
</ejs-toast>

<h2 style="margin-left: 20px;">My Files</h2>
<h5 style="margin-left: 20px;">Upload Files to My Personal Collection</h5>
<input type="file" style="display: none;" #fileSelected (change)="fileSelection($event)">
<button class="btn btn-sm btn-primary" style="margin: 10px; margin-left: 20px;" [disabled]="uploadInProgress"
  (click)="fileSelected.click()">Browse</button>
<input [value]="fileName">
<button class="btn btn-sm btn-success" style="margin: 10px;" [disabled]="!currentFile || uploadInProgress"
  (click)="uploadFile(uploadPublic)">Upload</button><span *ngIf="isAdmin">Upload Public:</span><ejs-checkbox style="margin-left: 10px;" *ngIf="isAdmin" [(ngModel)]="uploadPublic" ></ejs-checkbox><br>
<span *ngIf="uploadInProgress" style="color: red;">Upload In Progress! Please Wait...</span><br>

<span *ngIf="loadingMyFiles || loadingPubFiles" style="margin-left: 20px; color: red;"><b>---- Loading Files! Please Wait.... ----</b></span>

<h4 class="mt-3 file-table-header" style="margin-left: 20px;">My Personal File Collection</h4>
<span *ngIf="myfiles.length <= 1 && !loadingMyFiles" style="margin-left: 20px; color: red;"><b>---- No Files To Display! ----</b></span>
<table class="file-table" *ngIf="myfiles.length > 1">
    <tr>
        <th>File Name:</th>
        <th>File Type:</th>
        <th>Action:</th>
    </tr>
    <ng-template ngFor let-f [ngForOf]="myfiles" let-i="index">
        <tr *ngIf="!f.isDirectory || i > 0">
            <td><a href="javascript:undefined" role="button" (click)="downloadFile(f)">{{decodeString(f.displayName)}}</a></td>
            <td>{{f.contentType}}</td>
            <td>
                <button *ngIf="!f.isPublic || isAdmin" class="btn btn-sm btn-danger" (click)="deleteFile(f)">Delete</button>
                <span *ngIf="f.isPublic && !isAdmin">No Actions Available</span>
            </td>
        </tr>
    </ng-template>
</table>
<h4 class="mt-5 file-table-header" style="margin-left: 20px;">Public Shared Files</h4>
<span *ngIf="pubfiles.length <= 1 && !loadingPubFiles" style="margin-left: 20px; color: red;"><b>---- No Files To Display! ----</b></span>
<table class="file-table" *ngIf="pubfiles.length > 1">
    <tr>
        <th>File Name:</th>
        <th>File Type:</th>
        <th>Action:</th>
    </tr>
    <ng-template ngFor let-f [ngForOf]="pubfiles" let-i="index">
        <tr *ngIf="!f.isDirectory || i > 0">
            <td><a href="javascript:undefined" role="button" (click)="downloadFile(f)">{{decodeString(f.displayName)}}</a></td>
            <td>{{f.contentType}}</td>
            <td>
                <button *ngIf="!f.isPublic || isAdmin" class="btn btn-sm btn-danger" (click)="deleteFile(f)">Delete</button>
                <span *ngIf="f.isPublic && !isAdmin">No Actions Available</span>
            </td>
        </tr>
    </ng-template>
</table>