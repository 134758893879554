import { Component } from '@angular/core';
import { UserService } from '../services/user.service';
import { User } from '../user.class';
import { LoggerService } from '../services/logger.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent {
  pageTitle = "User List"
  users: User[] = [];
  constructor(
    private usrsvc: UserService,
    private sys: LoggerService,
    private router: Router,

  ){}

  setRowColor(rowStatus, disabled) {
    if(disabled){
      return "user-new";
    }
    else {
      switch(rowStatus){
        case "ACTIVE":
          return "user-active";
        case "NEW":
          return "user-new";
        case "COMPLETED":
          return "user-completed";
        case "DISABLED": 
          return "user-disabled";

      }
    }
    return "";
  }
  approveUser(userId) {
    this.usrsvc.updateUserStatus({ 'userId': userId, 'newStatus': "ACTIVE", 'isDisabled': false }).subscribe({
      next: (res) => {
        this.refresh();
      },
      error: (err) => {
        console.log(err);
      }
    });
  }
  disableUser(userId) {
    this.usrsvc.updateUserStatus({ 'userId': userId, 'newStatus': "DISABLED", 'isDisabled': true }).subscribe({
      next: (res) => {
        this.refresh();
      },
      error: (err) => {
        console.log(err);
      }
    });
  }
  refresh() {
    this.usrsvc.list().subscribe({
      next: (res) => {
        this.users = res;
      },
      error: (err) => {
        console.error(err);
      }
    });
  }
  ngOnInit(): void {
    if(!this.sys.isAdmin){
      this.router.navigate(['/404']);
    }
    this.refresh();
  }
}