import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Amateuren } from './amateuren.class';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AmateurSearchService {
  
  callsignSearch: string = "";
  // baseurl: string = "http://localhost:5000/api/ens"
  baseurl: string = environment.CalllogAPIurl + "/api/ens";
  
  constructor(
    private http: HttpClient
  ) { }
  getCallsign(callsign: string): Observable<Amateuren> {
    return this.http.get(`${this.baseurl}/callsign/${callsign}`, { headers: {['XApiKey']: `${sessionStorage.getItem('XApiKey')}`}}) as Observable<Amateuren>;
  }
}
