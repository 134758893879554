<nav class="navbar navbar-expand-lg navbar-light bg-light" id="filebar">
    <div class="container-fluid">
        <a class="navbar-brand" href="#"><span id="pageTitle">{{pageTitle}}</span></a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
            data-bs-target="#filemenuSupportedContent" aria-controls="filemenuSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="filemenuSupportedContent">
            <ul class="navbar-nav">
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle nav-link-filebar" href="#" id="navbarDropdownMenuLink"
                        role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        File
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <li><a class="dropdown-item" role="button" (click)="saveBtnClick()">Save</a></li>
                        <li *ngIf="pageTitle != 'Settings'"><a class="dropdown-item" routerLink="/settings/list/{{user?.id}}">User Settings</a></li>
                        <li><a class="dropdown-item" role="button" (click)="openSessionTimeoutModal()">Session Timer</a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle nav-link-filebar" href="#" id="navbarDropdownMenuLink"
                        role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Edit
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <li *ngIf="pageTitle == 'Hamlogs'"><a class="dropdown-item" role="button" (click)="editSelectedRows()">Edit Selected Rows</a>
                        </li>
                        <li *ngIf="pageTitle == 'Hamlogs'"><a class="dropdown-item" role="button" (click)="clearSelectedRows()">Deselect All Rows</a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle nav-link-filebar" href="#" id="navbarDropdownMenuLink"
                        role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Functions
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <li *ngIf="user && pageTitle != 'DX SPOTS'"><a class="dropdown-item" routerLink="/spots/config/{{user.id}}">DX-SPOTS</a>
                        </li>
                        <li *ngIf="user && pageTitle != 'Hamlogs'"><a class="dropdown-item" routerLink="/logging/newlog/{{user.id}}">Hamlogs</a>
                        </li>
                        
                        <li><a class="dropdown-item" href="#">Search QRZ</a></li>
                        <li><a class="dropdown-item" href="#">Search Previous Logs</a></li>
                        <li><a class="dropdown-item" href="#">Search FCC-US</a></li>
                        <li><a class="dropdown-item" href="#">Search Net Logs</a></li>
                        <li *ngIf="pageTitle == 'Hamlogs'"><a class="dropdown-item" role="button" (click)="recallNetsListModalFire()">Recall Net
                                Logs</a></li>
                    </ul>
                </li>
                <li *ngIf="userProfile?.rigControlEnabled" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle nav-link-filebar" href="#" id="navbarDropdownMenuLink"
                        role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Rig Control
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <li><a class="dropdown-item" role="button" (click)="rigControlBtnClick()">Control</a></li>
                        <li><a class="dropdown-item" role="button" (click)="rigControlSettingsClick()">Settings</a></li>
                        <li><a class="dropdown-item" role="button" (click)="rigControlConnectClick()">Connect</a></li>
                        <!-- <li><a class="dropdown-item" href="#">Close</a></li> -->
                    </ul>
                </li>
                <li class="nav-item">
                    <table>
                        <tr>
                            <td *ngIf="pageTitle == 'Hamlogs'" style="white-space: nowrap;"><span id="recordCount"
                                    *ngIf="searching && user && !isNetRtMode"> - Rec Count:
                                    {{recordCount}}</span>
                                <span id="recordCount" *ngIf="searching && isNetRtMode"> - Check-ins:
                                    {{checkincount}}</span>
                                <span id="searchResCount" *ngIf="!searching">{{searchResMessage}}</span>
                            </td>
                            <td *ngIf="!searching && !lookupPreviousLogs"><ejs-switch style="margin-left: 10px;"
                                    [(checked)]="showLookupPreviousLogs" (change)="setShowLookupPreviousLogs()"></ejs-switch></td>
                            <!-- <td width="85%"> -->
                            <td colspan="100%">
                                <!-- <div class="col-md-12" style="overflow: hidden;"> -->
                                <div class="d-flex justify-content-between align-items-center breaking-news"
                                    style="margin-left:10px;">
                                    <marquee id="marqueeScroller" class="news-scroll" behavior="scroll" direction="left"
                                        onmouseover="this.stop();" onmouseout="this.start();">
                                        <!-- preppend From DATABASE -->
                                        <span>W8ZJT Calllog Application</span>
                                    </marquee>
                                </div>
                                <!-- </div> -->
                            </td>
                        </tr>
                    </table>
                </li>
            </ul>
        </div>
    </div>
</nav>