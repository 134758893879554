import { Component, ViewChild } from '@angular/core';
import { LoggerService } from '../services/logger.service';
import { Router } from '@angular/router';
import { GroupSerive } from '../services/groups.service';
import { GroupUserXref } from '../groupUserXref.class';
import { ToastComponent } from '@syncfusion/ej2-angular-notifications';

@Component({
  selector: 'app-my-groups',
  templateUrl: './my-groups.component.html',
  styleUrl: './my-groups.component.css'
})
export class MyGroupsComponent {

  mygroups: GroupUserXref[] = [];

  @ViewChild('toastMessageBox') public toastMessageBox?: ToastComponent;
  public toastMessagePosition = { X: 'Right', Y: 'Top' };
  toastMessageContent: string = "";
  toastMessageTitle: string = "";

  constructor(
    private sys: LoggerService,
    private router: Router,
    private _groupSvc: GroupSerive,

  ){}

  toast(title, content, colorClass = "") {
    $('#toast').removeClass('toastRed');
    $('#toast').removeClass('toastGreen');
    (this.toastMessageBox as ToastComponent).cssClass = colorClass;
    this.toastMessageTitle = title;
    this.toastMessageContent = content;
    (this.toastMessageBox as ToastComponent).show();
  }

  deleteGroupReference(group: GroupUserXref) {
    this._groupSvc.deleteXref(group).subscribe({
      next: (res) => {
        console.log(res);
        this.toast("Success!", "Group Reference Deleted!", "toastGreen");
        this.refresh();
      },
      error: (err) => {
        console.error(err);
        this.toast("Error!", "Could Not Delete Group", "toastRed");
      }
    });
  }

  refresh() {
    this._groupSvc.getMyGroups(this.sys.userId).subscribe({
      next: (res) => {
        this.mygroups = res;
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  ngOnInit() {
    if(this.sys.isGroupAdmin){
      this.router.navigate(['/404']);
    }
    this.refresh();
  }
}
