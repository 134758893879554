export class RigControlData {
    radioSelected: string = "";
    serialAutoStart: boolean = false;
    baudRate: number = 0;
    pollingRateMs: number = 500;
    dataBits: number = 8;
    stopBits: number = 2;
    bufferSize: number = 256;
    parity: string = "none";
    flowControl: string = "none";
}