import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class AdifService {
    baseurl: string = environment.CalllogAPIurl + "/api/adif";

    constructor(
        private http: HttpClient
    ) { }

    uploadAdi(id: string, file: File): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', file);
        return this.http.post(`${this.baseurl}/upload/adi?userId=${id}`, formData, { headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<any>;
    }
    exportAdi(id: string): Observable<any> {
        return this.http.get(`${this.baseurl}/export/adi/${id}`, { responseType: 'blob', headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<any>;
    }

}