<!-- <p>serial-interface works!</p>
<div *ngIf="this.port">
    <span class="greendot"></span>
    <span>Connected</span>
</div>
<div *ngIf="!this.port">
    <span class="reddot"></span>
    <span>Disconnected</span>
</div>
<button (click)="connect()">Connect</button>
<button (click)="stopReadLoop()">Stop</button>
<button (click)="startReadLoop()">Read</button>
<ejs-textbox [(ngModel)]="this.radioCurrentFrequency"></ejs-textbox>
<ejs-textbox [(ngModel)]="this.newfreq"></ejs-textbox>
<button (click)="requestFrequencyChange()">Change Frequency</button>
<button (click)="openRigControlModal()">Control</button>
<button (click)="openSerialSettingsModal()">Settings</button>
<button id="runStartBtn" (click)="hiddenConnectButtonOnClick()" >AutoRun</button> -->


<!-- Modal Serial Settings -->
<div class="modal fade" id="SerialSettingsModal" tabindex="-1" aria-labelledby="SerialSettingsModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="SerialSettingsModalLabel">Serial Port Configuration</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <table class="serialTable">
                    <tr>
                        <th>Catagory</th>
                        <th *ngIf="this.availableRadios.length > 0">Radio</th>
                        <th>Auto</th>
                    </tr>
                    <tr>
                        <td>
                            <select [(ngModel)]="selectedCat" (change)="changeRig()">
                                <option *ngFor="let n of availableCatagories" [value]="n.value">{{n.text}}</option>
                            </select>
                        </td>
                        <td>
                            <select *ngIf="this.availableRadios.length > 0" [(ngModel)]="selectedRadio"
                                (change)="changeRadio()">
                                <option *ngFor="let n of this.availableRadios" [value]="n.name">{{n.name}}</option>
                            </select>
                        </td>
                        <td style="text-align: center;">
                            <ejs-switch [(checked)]="autoStartSerial" (change)="saveRigSettingsToProfile()"></ejs-switch>
                        </td>
                    </tr>
                </table>
                <table class="serialTable">
                    <tr>
                        <th>BaudRate</th>
                        <th>DataBits</th>
                        <th>StopBits</th>
                        <th>Parity</th>
                    </tr>
                    <tr>
                        <td>
                            <select [(ngModel)]="currentSerialOptions.baudRate">
                                <option *ngFor="let x of possibleBaudRates" [value]="x">{{x}}</option>
                            </select>
                        </td>
                        <td>
                            <select [(ngModel)]="currentSerialOptions.dataBits">
                                <option [value]="5">5</option>
                                <option [value]="6">6</option>
                                <option [value]="7">7</option>
                                <option [value]="8">8</option>
                            </select>
                        </td>
                        <td>
                            <select [(ngModel)]="currentSerialOptions.stopBits">
                                <option [value]="1">1</option>
                                <option [value]="2">2</option>
                            </select>
                        </td>
                        <td>
                            <select [(ngModel)]="currentSerialOptions.parity">
                                <option value="even">Even</option>
                                <option value="odd">Odd</option>
                                <option value="none">None</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th>Flow</th>
                        <th>Buffer</th>
                    </tr>
                    <tr>
                        <td>
                            <select [(ngModel)]="currentSerialOptions.flowControl">
                                <option value="none">None</option>
                                <option value="hardware">Hardware</option>
                            </select>
                        </td>
                        <td>
                            <select [(ngModel)]="currentSerialOptions.bufferSize">
                                <option [value]="256">256</option>
                                <option [value]="512">512</option>
                                <option [value]="1024">1024</option>
                            </select>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="modal-footer">
                <button class="btn btn-sm btn-success" data-bs-dismiss="modal" (click)="saveRigSettingsToProfile()">Save</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal Rig Control -->
<div class="modal fade" id="RigControlModal" tabindex="-1" aria-labelledby="RigControlModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="RigControlModalLabel">Rig Control</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <h5 *ngIf="this.port">Current Radio Data</h5>
                <h5 *ngIf="!this.port" style="color:red;">Disconnected!</h5>
                <div style="background-color: black; color: #8bf72d;">
                    <!-- <h3 style="padding: 10px;">7.258000 - LSB</h3> -->
                    <h3 style="padding: 10px;">{{radioCurrentFrequency}} - {{radioCurrentMode}}</h3>
                </div>
                <!-- <div class="container" style="background-color:white;"> -->
                <div class="btn-group-vertical mt-10" role="group">
                    <input style="font-weight: bold;" id="ShortCode" [disabled]="true">
                    <div class="btn-group">
                        <button type="button" class="btn btn-outline-secondary py-3" (click)="KeyPadWrite(1)">1</button>
                        <button type="button" class="btn btn-outline-secondary py-3" (click)="KeyPadWrite(2)">2</button>
                        <button type="button" class="btn btn-outline-secondary py-3" (click)="KeyPadWrite(3)">3</button>
                    </div>
                    <div class="btn-group">
                        <button type="button" class="btn btn-outline-secondary py-3" (click)="KeyPadWrite(4)">4</button>
                        <button type="button" class="btn btn-outline-secondary py-3" (click)="KeyPadWrite(5)">5</button>
                        <button type="button" class="btn btn-outline-secondary py-3" (click)="KeyPadWrite(6)">6</button>
                    </div>
                    <div class="btn-group">
                        <button type="button" class="btn btn-outline-secondary py-3" (click)="KeyPadWrite(7)">7</button>
                        <button type="button" class="btn btn-outline-secondary py-3" (click)="KeyPadWrite(8)">8</button>
                        <button type="button" class="btn btn-outline-secondary py-3" (click)="KeyPadWrite(9)">9</button>
                    </div>
                    <div class="btn-group">
                        <button type="button" class="btn btn-outline-danger py-6"
                            (click)="KeyPadWrite(-1)">&lt;</button>
                        <button type="button" class="btn btn-outline-secondary py-6" (click)="KeyPadWrite(0)">0</button>
                        <button type="button" class="btn btn-outline-secondary py-6"
                            (click)="KeyPadWrite('.')">.</button>
                    </div>
                    <!-- <button type="submit" class="btn btn-outline-success">OK</button> -->
                </div>
                <!-- </div> -->
                <select *ngIf="this.radio" style="margin-left: 10px;" [(ngModel)]="inputBoxModeRequest"
                    (change)="changeMode()">
                    <option *ngFor="let x of this.radio.modes" [value]="x">{{x}}</option>
                </select>
                <div style="margin-top: 10px;">
                    <button style="margin-bottom: 5px;" class="btn btn-primary btn-sm"
                        (click)="requestFrequencyChange()">Set Frequency</button>
                    <select style="margin-left: 10px;" [(ngModel)]="selectChangeVfo">
                        <option value="set_VFO_A">VFO-A</option>
                        <option value="set_VFO_B">VFO-B</option>
                    </select>
                </div>
            </div>
            <div class="modal-footer">

            </div>
        </div>
    </div>
</div>


<!-- <ejs-textbox [(ngModel)]="frequency" (ngModelChange)="requestFrequencyChange()"></ejs-textbox> -->