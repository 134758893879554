import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginPageComponent } from './user/login-page/login-page.component';
import { HomeComponent } from './home/home.component';
import { authGuard } from './auth-guard';
import { UserListComponent } from './user/user-list/user-list.component';
import { UserDetailComponent } from './user/user-detail/user-detail.component';
import { UserCreateComponent } from './user/user-create/user-create.component';
import { UserChangeComponent } from './user/user-change/user-change.component';
import { AmateurSearchComponent } from './amateur/amateur-search/amateur-search.component';
import { HamlogListcreateComponent } from './hamlog/hamlog-listcreate/hamlog-listcreate.component';
import { HamlogDetailComponent } from './hamlog/hamlog-detail/hamlog-detail.component';
import { HamlogChangeComponent } from './hamlog/hamlog-change/hamlog-change.component';
import { SettingsListComponent } from './settings/settings-list/settings-list.component';
import { RadioComponent } from './radio/radio/radio.component';
import { DxconfigComponent } from './dxspots/dxconfig/dxconfig.component';
import { DxdetailComponent } from './dxspots/dxdetail/dxdetail.component';
import { AboutComponent } from './core/about/about.component';
import { ChangesComponent } from './core/changes/changes.component';
import { DocumentationComponent } from './core/documentation/documentation.component';
import { RequestAccountComponent } from './user/request-account/request-account.component';
import { EditnewsitemComponent } from './settings/newsitems/editnewsitem/editnewsitem.component';
import { WeatherComponent } from './weather/weather/weather.component';
import { NetpresetsComponent } from './settings/netpresets/editnetpresets/netpresets.component';
import { accessRequestAuthGuard } from './request-access-guard';
import { NotFoundComponent } from './core/not-found/not-found.component';
import { NewpasswordComponent } from './user/newpassword/newpassword.component';
import { NewuserComponent } from './user/newuser/newuser.component';
import { GroupInvitationsComponent } from './user/group-invitations/group-invitations.component';
import { MyGroupsComponent } from './user/my-groups/my-groups.component';
import { MyMembersComponent } from './user/my-members/my-members.component';
import { MyFilesComponent } from './user/my-files/my-files.component';
import { FieldDayComponent } from './hamlog/contest/field-day/field-day.component';

const routes: Routes = [
  {path:"", component:HomeComponent},
  {path:"", redirectTo:"login", pathMatch:"full"},
  {path:"login", component:LoginPageComponent},
  {path:"about", component:AboutComponent},
  {path:"user/passwords/reset/:uid", component:NewpasswordComponent},
  {path:"user/create/new/user/:uid", component:NewuserComponent},
  {path:"request/:type", component:RequestAccountComponent, canActivate: [accessRequestAuthGuard]},
  {path:"weather", component:WeatherComponent},
  {path:"changes", component:ChangesComponent, canActivate: [authGuard]},
  {path:"settings/newsitems/edit", component:EditnewsitemComponent, canActivate: [authGuard]},
  {path:"settings/netpresets/edit", component:NetpresetsComponent, canActivate: [authGuard]},
  {path:"documentation", component:DocumentationComponent, canActivate: [authGuard]},
  {path:"radio", component:RadioComponent, canActivate: [authGuard]},
  {path:"user/list", component:UserListComponent, canActivate: [authGuard]},
  {path:"user/detail/:id", component:UserDetailComponent, canActivate: [authGuard]},
  {path:"user/change/:id", component:UserChangeComponent, canActivate: [authGuard]},
  {path:"user/create", component:UserCreateComponent, canActivate: [authGuard]},
  {path:"user/groups/invitations", component:GroupInvitationsComponent, canActivate: [authGuard]},
  {path:"user/groups/mygroups", component:MyGroupsComponent, canActivate: [authGuard]},
  {path:"user/files/myfiles", component:MyFilesComponent, canActivate: [authGuard]},
  {path:"user/groups/mymembers", component:MyMembersComponent, canActivate: [authGuard]},
  {path:"logging/newlog/:userId", component:HamlogListcreateComponent, canActivate: [authGuard]},
  {path:"logging/contest/fieldday/newlog/:userId", component:FieldDayComponent, canActivate: [authGuard]},
  {path:"amateur/search/:callsign", component:AmateurSearchComponent, canActivate: [authGuard]},
  {path:"hamlog/details/:id", component:HamlogDetailComponent, canActivate: [authGuard]},
  {path:"hamlog/update/:id", component:HamlogChangeComponent, canActivate: [authGuard]},
  {path:"settings/list/:id", component:SettingsListComponent, canActivate: [authGuard]},
  {path:"spots/config/:id", component:DxconfigComponent, canActivate: [authGuard]},
  {path:"spots/detail/:id", component:DxdetailComponent, canActivate: [authGuard]},
  {path: '404', component:NotFoundComponent}, 
  {path: '**', redirectTo: '/404'} // Wildcard route

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
