import { Component, ViewChild } from '@angular/core';
import { FileStorageService } from '../services/filestorage.service';
import { LoggerService } from '../services/logger.service';
import { FileDto } from '../filedto.class';
import { ToastComponent } from '@syncfusion/ej2-angular-notifications';
import { saveAs } from "file-saver";


@Component({
  selector: 'app-my-files',
  templateUrl: './my-files.component.html',
  styleUrl: './my-files.component.css'
})
export class MyFilesComponent {
  loadingMyFiles: boolean = false;
  loadingPubFiles: boolean = false;
  isAdmin: boolean = false;
  myfiles: FileDto[] = [];
  pubfiles: FileDto[] = [];
  uploadInProgress: boolean = false;
  uploadPublic: boolean = false;

  currentFile?: File;
  fileName = 'Select File';

  @ViewChild('toastMessageBox') public toastMessageBox?: ToastComponent;
  public toastMessagePosition = { X: 'Right', Y: 'Top' };
  toastMessageContent: string = "";
  toastMessageTitle: string = "";

  constructor(
    private _files: FileStorageService,
    private sys: LoggerService,
  ){}

  toast(title, content, colorClass = "") {
    $('#toast').removeClass('toastRed');
    $('#toast').removeClass('toastGreen');
    (this.toastMessageBox as ToastComponent).cssClass = colorClass;
    this.toastMessageTitle = title;
    this.toastMessageContent = content;
    (this.toastMessageBox as ToastComponent).show();
  }

  fileSelection(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file: File = event.target.files[0];
      this.currentFile = file;
      this.fileName = this.currentFile.name;
    } else {
      this.fileName = 'Select File';
    }
  }

  downloadFile(data: FileDto){
    this._files.getFile(data, this.sys.userId).subscribe({
      next: (res) => {
        saveAs(res, this.decodeString(data.displayName));
      },
      error: (err) => {
        console.error(err);
      }
    });
  }
  deleteFile(data: FileDto){
    this._files.deleteFile(data, this.sys.userId).subscribe({
      next: (res) => {
        console.log(res.isSuccess);
        this.toast("Success!", "File Deleted Successfully!", "toastGreen");
        this.refresh();
      },
      error: (err) => {
        console.error(err);
        this.toast("Error", "File Delete Failed!", "toastRed");
      }
    });
  }

  uploadFile(isPrivate: boolean){
    this.uploadInProgress = true;
    if(this.currentFile){
      this._files.uploadFile(this.currentFile, this.sys.userId, !isPrivate ? "private" : "public").subscribe({
        next: (res) => {
          console.log(res.isSuccess)
          this.toast("File Upload Success!", "File was uploaded sucessfully.", "toastGreen");
          this.fileName = 'Select File';
          this.currentFile = undefined;
          this.uploadInProgress = false;
          this.refresh();
        },
        error: (err) => {
          console.error(err);
          this.uploadInProgress = false;
          this.toast("Failed To Upload File", "File was not uploaded due to failure.", "toastRed");
        }
      });

    }
  }

  loadFiles() {
    this.loadingMyFiles = true;
    this._files.list(this.sys.userId).subscribe({
      next: (res) => {
        this.myfiles = res;
        this.loadingMyFiles = false;
      },
      error: (err) => {
        console.error(err);
        this.loadingMyFiles = false;
      }
    });
    this.loadingPubFiles = true;
    this._files.publist().subscribe({
      next: (res) => {
        this.pubfiles = res;
        this.loadingPubFiles = false;
      },
      error: (err) => {
        console.error(err);
        this.loadingPubFiles = false;
      }
    });
  }
  decodeString(string: string) {
    return decodeURI(string)
  }
  refresh() {
    this.isAdmin = this.sys.isAdmin;
    this.loadFiles();
  }

  ngOnInit() {
    this.refresh();
  }

}
