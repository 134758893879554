import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { NewInvitation } from "../requests.class";
import { GroupInvitation } from "../groupInvitation.class";
import { GroupUserXref } from "../groupUserXref.class";
import { User } from "../user.class";

@Injectable({
    providedIn: 'root'
})

export class GroupSerive {

    baseurl: string = environment.CalllogAPIurl + '/api/group';

    constructor(
        private http: HttpClient,
    ) { }

    sendInvite(data: NewInvitation): Observable<any> {
        return this.http.post(`${this.baseurl}/groups/new/invitation`, data, { headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<any>;
    }
    actionInvite(data: GroupInvitation, action: string): Observable<any> {
        return this.http.post(`${this.baseurl}/groups/invites/${action}`, data, { headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<any>;
    }
    getGroupMembers(groupId: string): Observable<GroupUserXref[]> {
        return this.http.get(`${this.baseurl}/groups/members/list${groupId}`, { headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<GroupUserXref[]>;
    }
    getMyGroups(userId: string): Observable<GroupUserXref[]> {
        return this.http.get(`${this.baseurl}/groups/get/mygroups/list${userId}`, { headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<GroupUserXref[]>;
    }
    getSenderInvites(id: string, expired, all: boolean): Observable<GroupInvitation[]> {
        return this.http.get(`${this.baseurl}/groups/get/sender/invites/${id}/${expired}/${all}`, { headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<GroupInvitation[]>;
    }
    getRecipiantInvites(id: string, expired: boolean, all: boolean): Observable<GroupInvitation[]> {
        return this.http.get(`${this.baseurl}/groups/get/recipiant/invites/${id}/${expired}/${all}`, { headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<GroupInvitation[]>;
    }
    deleteXref(group: GroupUserXref): Observable<any> {
        return this.http.post(`${this.baseurl}/groups/delete/xrefs`, group, { headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<any>;
    }
    updateGroupNames(user: User): Observable<any> {
        return this.http.post(`${this.baseurl}/groups/update/name`, user, { headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<any>;
    }
    deleteAllXrefs(userId: string): Observable<any> {
        return this.http.delete(`${this.baseurl}/groups/delete/allxrefs/${userId}`, { headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<any>;
    }


}