import { Component, ElementRef, ViewChild } from '@angular/core';
import { UserService } from '../services/user.service';
import { User } from '../user.class';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoggerService } from '../services/logger.service';
import { encrypt } from '../dsi-encrypt'
import { DatePipe } from '@angular/common';
import { UserSettingService } from '../services/user-setting.service';
import { jwtDecode } from 'jwt-decode';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';
import { LoginCredentials, RestoreUserData } from '../logincredentials.class';
import { v4 as uuidv4 } from 'uuid';
import { AdditionalData } from '../additionaldata.class';
import { DefaultGuid, LogURLs, NumberDateToJson } from 'src/app/settings/globalVars';
import { LogMode } from '../logmode.class';



@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent {
  public loginForm!: FormGroup
  private user: User = {} as any;
  message: string = "";
  pageTitle = "W8ZJT CallLog";
  pipe = new DatePipe('en-US');
  @ViewChild('resetForm') aForm!: ElementRef;
  username: FormControl = new FormControl('', Validators.required);
  password: FormControl = new FormControl('', Validators.required);
  instanceId: string = "";
  clientData!: AdditionalData;


 
  constructor(
    private svcusr: UserService,
    private loggersvc: LoggerService,
    private formbuilder: FormBuilder,
    private setUserSvc: UserSettingService,
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service
  ){
    this.loginForm = this.formbuilder.group({
      username: this.username,
      password: this.password
    });
    let iid = localStorage.getItem("instanceId");
    if(iid == null || iid == "" || iid == undefined){
      let newiid = uuidv4();
      localStorage.setItem("instanceId", newiid);
      this.instanceId = newiid;
    }
    else {
      this.instanceId = iid;
    }
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch(Error) {
      return null;
    }
  }
  setFocus(name) {
    const ele = this.aForm.nativeElement[name];
    if (ele) {
      ele.focus();
    }
  }
  ngAfterViewInit() {
    this.setFocus('username');
  }
  ngOnInit(): void {
    var ssUserId = sessionStorage.getItem('userId');
    if(sessionStorage.getItem('isLoggedIn') == 'true' && ssUserId !== null){
      let data = new RestoreUserData();
      data.userId = ssUserId;
      data.instanceId = this.instanceId;
      let tokenexp = sessionStorage.getItem('TokenExp');
      if(tokenexp != null){
        let outExp = new Date().getTime();
        data.exp = NumberDateToJson(outExp);
      }
      this.svcusr.restore(data).subscribe({
        next: (res) => {
          this.user = res;
          if(this.user){
            let pURL = sessionStorage.getItem('pURL');
            this.loggersvc.isLoggedin = true;
            this.loggersvc.user = this.user;
            this.loggersvc.isAdmin = this.user.isAdmin;
            this.loggersvc.userCtrlLevel = this.user.userCtrlLevel;
            this.loggersvc.userId = this.user.id;
            this.loggersvc.instanceId = this.instanceId;
            let contest = sessionStorage.getItem('contest');
            let mode = sessionStorage.getItem('mode');
            if(mode != null && mode == "CONTEST"){
              if(contest != null){
                this.loggersvc.logURL = LogURLs[contest];
              }
            }
            else {
              this.loggersvc.logURL = LogURLs["master"]
            }
            if(this.user.operateAsGroupId != null){
              this.loggersvc.groupId = this.user.operateAsGroupId;
            }
            this.loggersvc.username = this.user.userName;
            this.loggersvc.isGroupAdmin = this.user.isGroupAdmin;
            if(pURL != null){
              this.router.navigate([`${pURL}`])
            }
            else{
              this.router.navigate([`/logging/newlog/${this.user.id}`]);
            }
          }
        }
      })
    }
    else {
      this.getClientIP();
    }
  }

  validateCaptcha() {
    this.recaptchaV3Service.execute('importantAction')
    .subscribe({
      next: (res) => {
        this.loginuser(res);
      },
      error: (err) => {
        this.message = "Recaptcha could not be validated.";
      }
    });
  }
  getClientIP () {
    this.svcusr.getUserIP().subscribe({
      next: (res) => {
        this.clientData = res;
      }
    });
  }
  async loginuser(token){
    var loginCreds: LoginCredentials = new LoginCredentials();
    loginCreds.token = token
    let password = encrypt(this.loginForm.value.password);
    loginCreds.userName = this.loginForm.value.username;
    loginCreds.password = password;
    loginCreds.instanceId = this.instanceId;
    loginCreds.additionalData = JSON.stringify(this.clientData);
    this.svcusr.login(loginCreds).subscribe({
      next: (res) => {
        this.user = res
        if(this.user){
          this.loggersvc.isLoggedin = true;
          this.loggersvc.user = this.user;
          this.loggersvc.userId = this.user.id;
          if(this.user.operateAsGroupId != null){
            this.loggersvc.groupId = this.user.operateAsGroupId;
          }
          this.loggersvc.username = this.user.userName;
          this.loggersvc.isAdmin = this.user.isAdmin;
          this.loggersvc.userCtrlLevel = this.user.userCtrlLevel;
          this.loggersvc.instanceId = this.instanceId;
          this.loggersvc.isGroupAdmin = this.user.isGroupAdmin;
          var keyData = this.getDecodedAccessToken(this.user.xApiKey.toString());
          sessionStorage.setItem('TokenExp', keyData.exp);
          sessionStorage.setItem('XApiKey', this.user.xApiKey.toString());
          sessionStorage.setItem('isLoggedIn', 'true');
          sessionStorage.setItem('isAdmin', this.user.isAdmin.toString());
          sessionStorage.setItem('isSubAdmin', this.user.userCtrlLevel);
          sessionStorage.setItem('userId', this.user.id.toString());
          sessionStorage.setItem('qrze', this.user.qrzLookupEnabled.toString());
          if(this.user.qrzLookupEnabled){
            sessionStorage.setItem('qrzu', this.user.qrzUserName.toString());
            sessionStorage.setItem('qrzp', this.user.qrzPassword.toString());
          }
          console.log("Login Successful");
          this.loginForm.reset();
          if(this.user.selectedSettingsProfileId != null){
            this.setUserSvc.get(this.user.selectedSettingsProfileId).subscribe({
              next: (res) => {
                sessionStorage.setItem('selectedProfile', JSON.stringify(res));
                this.loggersvc.selectedProfile = res;
                let logmodesettings: LogMode = JSON.parse(this.loggersvc.selectedProfile.logMode)
                sessionStorage.setItem('mode', logmodesettings.logMode);
                sessionStorage.setItem('contest', logmodesettings.contest);
                if(logmodesettings.logMode == "CONTEST"){
                  this.loggersvc.logURL = LogURLs[logmodesettings.contest]
                }
                else {
                  this.loggersvc.logURL = LogURLs["master"]
                }
                let pURL = sessionStorage.getItem('pURL');
                if(pURL != null && pURL != this.loggersvc.logURL + DefaultGuid){
                  this.router.navigate([`${pURL}`])
                }
                else{
                  this.router.navigate([this.loggersvc.logURL + this.user.id]);
                }
              },
              error: (err) => {
                console.error(err);
                if(typeof err.error == "object"){
                  this.message = err.error.title;
                }
                if(typeof err.error == "string"){
                  this.message = err.error;
                }
              }
            });
          }
        }
      },
      error: (err) => {
        console.error(err);
        if(typeof err.error == "object"){
          this.message = err.error.title;
        }
        if(typeof err.error == "string"){
          this.message = err.error;
        }
      }
    })
  }
}
