import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NetPreset } from './netpreset.class';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NetPresetService {

  constructor(
    private http: HttpClient
  ) { }

  baseurl: string = environment.CalllogAPIurl + "/api/netpresets";

  list(userId: string): Observable<NetPreset[]> {
    return this.http.get(`${this.baseurl}/list/${userId}`, { headers: {['XApiKey']: `${sessionStorage.getItem('XApiKey')}`}}) as Observable<NetPreset[]>;
  }
  updateList(netPresets: NetPreset[]): Observable<any> {
    return this.http.post(`${this.baseurl}/update/netpresets`, netPresets, { headers: {['XApiKey']: `${sessionStorage.getItem('XApiKey')}`}}) as Observable<any>;
  }
  remove(id: string): Observable<any> {
    return this.http.delete(`${this.baseurl}/${id}`, { headers: {['XApiKey']: `${sessionStorage.getItem('XApiKey')}`}}) as Observable<any>;
  }

}