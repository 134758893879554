<app-menu></app-menu>
<h1>Documentation</h1>
<table>
    <tr>
        <th>Name</th>
        <th>Rig</th>
        <th>Read Frequency</th>
        <th>Read Mode</th>
        <th>ConvertToHex</th>
        <th>Baud Rate</th>
        <th>Parity</th>
        <th>StopBits</th>
        <th>DataBits</th>
        <th>Power Options</th>
        <th>Other</th>
    </tr>
    <tr *ngFor="let n of supportedRadios">
        <td>{{n.Name}}</td>
        <td>{{n.RigName}}</td>
        <td>{{n.ReadFrequencyCommand}}</td>
        <td>{{n.ReadModeCommand}}</td>
        <td>{{n.ConvertToHex}}</td>
        <td>{{n.BaudRate}}</td>
        <td>{{n.Parity}}</td>
        <td>{{n.StopBits}}</td>
        <td>{{n.DataBits}}</td>
        <td>{{n.PowerOption}}</td>
        <th>{{n.Other}}</th>
    </tr>
</table>