import { Component, viewChild, ViewChild } from '@angular/core';
import { UserService } from 'src/app/user/services/user.service';
import { User } from 'src/app/user/user.class';
import { LoggerService } from 'src/app/user/services/logger.service';
import { HamlogService } from 'src/app/hamlog/hamlog.service';
import { Observable, Subscription } from 'rxjs';
import { UserSetting } from 'src/app/user/userSetting.class';
import { UserSettingService } from 'src/app/user/services/user-setting.service';
import { SerialInterfaceComponent } from 'src/app/rig_interface/serial-interface/serial-interface.component';
import { ToastComponent } from '@syncfusion/ej2-angular-notifications';
import { NewInvitation } from 'src/app/user/requests.class';
import { GroupInvitation } from 'src/app/user/groupInvitation.class';
import { saveAs } from "file-saver";
import { BackupService } from 'src/app/user/services/backup.service';
import { GroupSerive } from 'src/app/user/services/groups.service';
import { AdifService } from 'src/app/hamlog/adif.service';
import { GroupUserXref } from 'src/app/user/groupUserXref.class';
import { MenuComponent } from 'src/app/menu/menu/menu.component';
import { LogMode } from 'src/app/user/logmode.class';
import { Contests, LogModeOptions, LogURLs } from '../globalVars';



@Component({
  selector: 'app-settings-list',
  templateUrl: './settings-list.component.html',
  styleUrls: ['./settings-list.component.css']
})
export class SettingsListComponent {


  user!: User;
  groupInvitations: GroupInvitation[] = [];
  myGroups: GroupUserXref[] = [];
  operateAsGroup: boolean = false;
  selectedGroupId: string | null = null;
  showGroupSelect: boolean = false;
  jsonStyleString: string = "";
  styleColor: string = "";
  userId: string = "";
  currentFile?: File;
  progress = 0;
  fileName = 'Select File';
  fileInfos?: Observable<any>;
  intervalId: any;
  newLogRowCount: number = 0;
  newLogDuplicates: number = 0;
  uploadSuccess: boolean = false;
  currentlyUploading: boolean = false;
  uploadSub!: Subscription;
  newProfile: UserSetting = new UserSetting;
  currentSelectedProfile!: UserSetting;
  selectProfileId: string = "";
  availableProfiles!: UserSetting[];
  editableProfile: boolean = false;
  modalDeleteOrCancel: string = "Delete";
  areYouSureModalDelete: boolean = false;
  originalPnameEdit: string = "";
  qrzEnabled: boolean = false;
  numberOfCallsToShow: number[] = [5, 10, 12, 15, 20, 50];
  logModeOptions!: LogMode;
  newLogModeOption: string = "NORMAL";
  newContestOption: string = "";
  logModeBaseOptions: string[] = LogModeOptions;
  contests = Contests;
  automaticLookupPreviousLogs: boolean = false;
  newAutomaticLookupPreviousLogs: boolean = true;
  use24Hour: boolean = false;
  newUse24Hour: boolean = false;
  qrzUser: string = "";
  qrzPwd: string = "";
  groupAdminEnabled: boolean = false;
  groupName: string = "";
  inviteCallsign: string = "";
  inviteSendEmail: boolean = true;
  inviteMessage: string = "";
  dateNow = Date.now();
  downloadingBackup: boolean = false;
  downloadingExport: boolean = false;
  restoreHamlogs: boolean = false;
  restoreNetPresets: boolean = false;
  restoreGroups: boolean = false;
  restoreUserSettings: boolean = false;
  restoreInProgress: boolean = false;
  public fields: Object = { text: 'profileName', value: 'id' };
  public groupfields: Object = { text: 'groupName', value: 'userId' };
  public contestfields: Object = { text: 'text', value: 'value' }
  @ViewChild('rigcontrol') public rigControl?: SerialInterfaceComponent;

  @ViewChild('toastMessageBox') public toastMessageBox?: ToastComponent;
  public toastMessagePosition = { X: 'Right', Y: 'Top' };
  toastMessageContent: string = "";
  toastMessageTitle: string = "";

  @ViewChild('appmenu') public appmenu?: MenuComponent;


  constructor(
    private usrsvc: UserService,
    private sys: LoggerService,
    private hamsvc: HamlogService,
    private userSetSvc: UserSettingService,
    private _buService: BackupService,
    private _groupSvc: GroupSerive,
    private _adif: AdifService,



  ) { }
  toast(title, content, colorClass = "") {
    $('#toast').removeClass('toastRed');
    $('#toast').removeClass('toastGreen');
    (this.toastMessageBox as ToastComponent).cssClass = colorClass;
    this.toastMessageTitle = title;
    this.toastMessageContent = content;
    (this.toastMessageBox as ToastComponent).show();
  }

  toDate(val): Date {
    return (val as Date);
  }
  setStatus(accepted, expires) {
    let outStatus = "";
    if (accepted == null) {
      outStatus = "PENDING";
    }
    if (this.parseDateTime(expires) < this.parseDateTime(this.dateNow)) {
      outStatus = "EXPIRED";
    }
    if (accepted == true) {
      outStatus = "ACCEPTED";
    }
    if (accepted == false) {
      outStatus = "DECLINED";
    }
    return outStatus;
  }

  parseDateTime(jsonDate) {
    let dateObject = new Date(jsonDate != null ? jsonDate : "");
    return dateObject //.toLocaleString('en-US');
  }
  openSessionTimeoutModal() {
    ($('#TimeOutModal') as any).modal('show');
  }
  isProfileNameValid(name: string) {
    let isValid = true;
    if (name.toLowerCase() == "default profile" || name == "") {
      isValid = false;
    }
    else {
      this.availableProfiles.forEach((profile) => {
        if (name.toLowerCase() == profile.profileName.toLowerCase()) {
          isValid = false;
        }
      });
    }
    return isValid;
  }
  resetModalOptions() {
    this.modalDeleteOrCancel = "Delete";
    this.areYouSureModalDelete = false;
  }
  deleteProfileFromModal(): void {
    this.areYouSureModalDelete = !this.areYouSureModalDelete;
    this.modalDeleteOrCancel = !this.areYouSureModalDelete ? "Delete" : "Cancel?";
  }
  deleteFromModalVerified(): void {
    this.userSetSvc.remove(this.selectProfileId).subscribe({
      next: (res) => {
        console.debug("Deleted!");
        this.deleteProfileFromModal();
        this.refresh();
        this.availableProfiles.forEach((profile) => {
          if (profile.profileName.toLowerCase() == "default profile") {
            this.user.selectedSettingsProfileId = profile.id;
          }
        });
        this.usrsvc.change(this.user).subscribe({
          next: (res) => {
            console.log("Selected User Profile Changed To Default Profile");
            this.refresh();
          },
          error: (err) => {
            console.error(err);
          }
        });
      },
      error: (err) => {
        console.error(err);
      }
    })
  }

  editSaveSelectedProfile(skipValidate = false) {
    let isValid = true;
    this.logModeOptions.autoLookup = this.automaticLookupPreviousLogs;
    this.logModeOptions.use24Hour = this.use24Hour;
    if(this.logModeOptions.logMode != "CONTEST"){
      this.logModeOptions.contest = "";
    }
    this.currentSelectedProfile.logMode = JSON.stringify(this.logModeOptions);
    

    if (!skipValidate) {
      if (this.currentSelectedProfile.profileName.toLowerCase() != this.originalPnameEdit.toLowerCase()) {
        isValid = this.isProfileNameValid(this.currentSelectedProfile.profileName);
      }
    }
    if (isValid) {
      this.userSetSvc.edit(this.currentSelectedProfile.id, this.currentSelectedProfile).subscribe({
        next: (res) => {
          console.log("User Profile Updated");
          this.refresh();
        },
        error: (err) => {
          console.error(err);
        }
      });
    }
    else {
      this.toast("Error!", "Cannot Save Changes, Profile Name Already Exists.", "toastRed");
      console.error("Profile Name Exists");
      this.refresh();
    }
  }
  setLogUrl() {
    if(this.logModeOptions.logMode == "CONTEST"){
      this.sys.logURL = LogURLs[this.logModeOptions.contest];
    }
    else {
      this.sys.logURL = LogURLs["master"];
    }
  }

  // on edit modal open
  setOriginalPname() {
    this.originalPnameEdit = this.currentSelectedProfile.profileName;
  }

  changeSelectedProfile(event: any) {
    if (event.isInteracted) {
      this.user.selectedSettingsProfileId = this.selectProfileId;
      this.usrsvc.change(this.user).subscribe({
        next: (res) => {
          console.log("Selected User Profile Updated!");
          this.toast("Success!", "Selected Profile Updated", "toastGreen");
          this.refresh();
        },
        error: (err) => {
          console.error(err);
        }
      });
    }
  }
  selectGroupBtn() {
    this.showGroupSelect = true;
  }
  disableSelectedGroup() {
    this.user.operateAsGroupName = null;
    this.user.operateAsGroupId = null;
    this.user.operateAsGroup = false;
    this.usrsvc.change(this.user).subscribe({
      next: (res) => {
        console.log("Group Settings Updated!");
        this.toast("Success!", "Group Settings Updated", "toastGreen");
        this.refresh();
      },
      error: (err) => {
        console.error(err);
      }
    });
  }
  changeSelectedGroup(event: any) {
    if (event.isInteracted) {
      let group = this.myGroups.find(n => n.userId == this.selectedGroupId);
      console.log(group)
      if(group != undefined){
        this.user.operateAsGroupName = group.groupName;
        this.user.operateAsGroupId = group.userId;
        this.user.operateAsGroup = true;
      }
      this.usrsvc.change(this.user).subscribe({
        next: (res) => {
          console.log("Group Settings Updated!");
          this.toast("Success!", "Group Settings Updated", "toastGreen");
          this.refresh();
        },
        error: (err) => {
          console.error(err);
        }
      });
    }
  }
  deleteAllXrefs() {
    this._groupSvc.deleteAllXrefs(this.user.id).subscribe({
      next: (res) =>  {

      },
      error: (err) => {
        console.error(err);
      }
    });
  }
  updateGroupXrefName() {
    this._groupSvc.updateGroupNames(this.user).subscribe({
      next: (res) => {

      },
      error: (err) => {
        console.error(err);
      }
    });
  }
  changeUserGroupAdmin() {
    if(this.user.isGroupAdmin == true && this.groupAdminEnabled == false){
      this.deleteAllXrefs();
      this.user.groupName = "";
    }
    else {
      this.user.groupName = this.groupName;
    }
    this.user.isGroupAdmin = this.groupAdminEnabled;
    this.updateGroupXrefName();
    this.usrsvc.change(this.user).subscribe({
      next: (res) => {
        this.toast("Success!", "User has been updated.", "toastGreen");
        this.refresh();
        this.sys.isGroupAdmin = this.user.isGroupAdmin;
        this.appmenu?.renew();
      },
      error: (err) => {
        console.error(err);
      }
    });
  }
  sendInvite() {
    let invite = new NewInvitation(this.inviteCallsign, this.user.id, this.inviteSendEmail, this.inviteMessage);
    this._groupSvc.sendInvite(invite).subscribe({
      next: (res) => {
        if (res.isSuccess) {
          this.toast("Success!", "Invitation sent!", "toastGreen");
          this.inviteCallsign = "";
          this.inviteMessage = "";
          this.refresh();
        }
      },
      error: (err) => {
        console.log(err);
        let message = "";
        if (typeof err.error == "object") {
          message = err.error.title;
        }
        if (typeof err.error == "string") {
          message = err.error;
        }
        this.toast("Error!", message, "toastRed");
        this.inviteCallsign = "";
      }
    });
  }
  changeUserQRZsettings() {
    this.user.qrzLookupEnabled = this.qrzEnabled;
    this.user.qrzUserName = this.usrsvc.encryptData(this.qrzUser);
    this.user.qrzPassword = this.usrsvc.encryptData(this.qrzPwd);
    sessionStorage.setItem('qrze', this.qrzEnabled ? "true" : "false");
    sessionStorage.setItem('qrzSessionKey', "");
    sessionStorage.setItem('qrzu', this.user.qrzUserName);
    sessionStorage.setItem('qrzp', this.user.qrzPassword);
    this.usrsvc.change(this.user).subscribe({
      next: (res) => {
        console.log("QRZ Settings successfully updated");
        this.toast("Success!", "QRZ Settings Updated.", "toastGreen");
        this.refresh();
      },
      error: (err) => {
        console.error(err);
      }
    });
  }
  createNewUserProfile() {
    var isValid = this.isProfileNameValid(this.newProfile.profileName);
    if (isValid) {
      this.newProfile.logMode = "";
      let logModeData = new LogMode;
      logModeData.autoLookup = this.newAutomaticLookupPreviousLogs;
      logModeData.use24Hour = this.newUse24Hour;
      logModeData.contest = this.newContestOption;
      logModeData.logMode = this.newLogModeOption;
      if(logModeData.logMode != "CONTEST") {
        logModeData.contest = "";
      }
      this.newProfile.logMode = JSON.stringify(logModeData);
      this.newProfile.userId = this.user.id;
      this.userSetSvc.create(this.newProfile).subscribe({
        next: (res) => {
          console.log("New Setting Profile Created");
          this.refresh();
          this.newProfile = new UserSetting;
        },
        error: (err) => {
          console.error(err);
        }
      });
    }
    else {
      this.toast("Error!", "Error Creating Profile, Profile Name Already Exists.", "toastRed");
      console.error("Profile Name Exists");
      this.refresh();
      this.newProfile = new UserSetting;
    }
  }
  fileSelection(event: any): void {
    this.progress = 0;
    if (event.target.files && event.target.files[0]) {
      const file: File = event.target.files[0];
      this.currentFile = file;
      this.fileName = this.currentFile.name;
    } else {
      this.fileName = 'Select File';
    }
  }
  resetUploadModal() {
    this.currentlyUploading = false;
  }
  startProgressReport() {
    this.intervalId = setInterval(() => {
      this.usrsvc.getUploadProgress(this.user.id).subscribe({
        next: (res) => {
          this.progress = res;
          if (this.progress == 100) {
            this.stopProgressReport();
            this.currentlyUploading = true;
            this.uploadSuccess = true;
            this.resetUploadModal();
          }
        },
        error: (err) => {
          console.error(err);
          this.stopProgressReport();
          this.currentlyUploading = false;
          this.uploadSuccess = false;
        }
      })
    }, 5000);
  }
  stopProgressReport() {
    clearInterval(this.intervalId);
  }
  cancelUpload() {
    this.uploadSub.unsubscribe();
    this.stopProgressReport();
    this.currentlyUploading = false;
    this.uploadSuccess = false;
    this.progress = 0;
  }
  exportBackup() {
    this.downloadingBackup = true;
    this._buService.exportBackup(this.userId).subscribe({
      next: (res) => {
        this.downloadingBackup = false;
        saveAs(res, `${this.user.callsign}_BACKUP_${Date.now()}.json`)
      },
      error: (err) => {
        this.downloadingBackup = false;
        this.toast("Error Downloading", "We encountered and error creating the backup file.", "toastRed");
        console.error(err);
      }
    });
  }
  exportFileAdi() {
    this.downloadingExport = true;
    this._adif.exportAdi(this.userId).subscribe({
      next: (res) => {
        this.downloadingExport = false;
        saveAs(res, `${this.user.callsign}_${this.userId}_${Date.now()}.adi`)
      },
      error: (err) => {
        this.downloadingBackup = false;
        this.toast("Error Downloading", "We encountered and error creating the ADIF file.", "toastRed");
        console.error(err);
      }
    });
  }
  uploadFileJson() {
    if (this.currentFile) {
      this.restoreInProgress = true;
      this.uploadSub = this._buService
        .restoreFromBackup(this.user.id,
          this.currentFile,
          this.restoreHamlogs,
          this.restoreUserSettings,
          this.restoreGroups,
          this.restoreNetPresets
        ).subscribe({
          next: (res) => {
            console.log(res);
            this.uploadSuccess = true;
            this.restoreInProgress = false;
          },
          error: (err) => {
            console.error(err);
            this.restoreInProgress = false;
            this.uploadSuccess = false;
          }
        });
    }
  }
  uploadFileAdi(): void {
    if (this.currentFile) {
      this.progress = 0;
      this.startProgressReport();
      this.currentlyUploading = true;
      this.uploadSub = this._adif.uploadAdi(this.user.id, this.currentFile).subscribe({
        next: (res) => {
          console.log(res);
          this.uploadSuccess = true;
          this.currentlyUploading = false;
          this.newLogRowCount = res.rowCount;
          this.newLogDuplicates = res.duplicates;
          this.uploadSuccess = res.importResult;

        },
        error: (err) => {
          console.error(err);
          this.currentlyUploading = false;
          this.uploadSuccess = false;
          this.stopProgressReport();

        }
      });
    }
  }

  save(): void {
    this.usrsvc.change(this.user).subscribe({
      next: (res) => {
        console.debug("User Settings Updated");
        // this.router.navigateByUrl(`/logging/newlog/${this.userId}`);


      },
      error: (err) => {
        console.error(err);
      }
    })
  }
  refresh(): void {
    this.userId = this.sys.userId;
    this.usrsvc.get(this.userId).subscribe({
      next: (res) => {
        this.user = res;
        this.groupName = this.user.groupName;
        this.selectedGroupId = this.user.operateAsGroupId;
        this.showGroupSelect = this.user.operateAsGroup;
        this.groupAdminEnabled = this.user.isGroupAdmin;
        this.qrzEnabled = this.user.qrzLookupEnabled;
        this.availableProfiles = res.userSettings;
        if (this.user.qrzUserName != null) {
          this.qrzUser = this.usrsvc.decryptData(this.user.qrzUserName);
        }
        if (this.user.qrzPassword != null) {
          this.qrzPwd = this.usrsvc.decryptData(this.user.qrzPassword);
        }
        this.userSetSvc.get(this.user.selectedSettingsProfileId).subscribe({
          next: (res) => {
            this.currentSelectedProfile = res;
            this.selectProfileId = res.id;
            if (res.profileName == "Default Profile") {
              this.editableProfile = false;
            }
            else {
              this.editableProfile = true;
            }
            this.logModeOptions = JSON.parse(res.logMode);
            this.automaticLookupPreviousLogs = this.logModeOptions.autoLookup;
            this.use24Hour = this.logModeOptions.use24Hour;
            this.setLogUrl();
          },
          error: (err) => {
            console.error(err);
          }
        });
        this._groupSvc.getSenderInvites(this.user.id, true, true).subscribe({
          next: (res) => {
            this.groupInvitations = res;
          },
          error: (err) => {
            console.error(err);
          }
        });
        this._groupSvc.getMyGroups(this.sys.userId).subscribe({
          next: (res) => {
            this.myGroups = res;
          },
          error: (err) => {
            console.error(err);
          }
        });
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  ngOnInit(): void {
    this.refresh();
  }
}
