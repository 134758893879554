<app-menu (onHamlogTask)="hamlogUpdateRequest()"></app-menu>
<app-time-out-modal></app-time-out-modal>

<!-- Modal -->
<div class="modal fade" id="EditHamlogCollectionModal" tabindex="-1" aria-labelledby="EditHamlogCollectionModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" *ngIf="this.selectedRecords.length > 1" id="EditHamlogCollectionModalLabel">Edit
                    {{this.selectedRecords.length}} Selected Records</h5>
                <h5 class="modal-title" *ngIf="this.selectedRecords.length == 1" id="EditHamlogCollectionModalLabel">
                    Edit {{this.selectedRecords.length}} Selected Record</h5>
            </div>
            <div class="modal-body">

                <span style="color: red;">
                    Note: Select the checkbox if you wish to update the field for all selected rows. Unselected checkbox
                    fields will not be changed.
                </span>
                <table>
                    <tr>
                        <td><ejs-checkbox cssClass="multiEditCB" [(checked)]="selectedRecordsNewTonCB"></ejs-checkbox>
                        </td>
                        <td><span style="font-weight: bold;">Date: </span></td>
                        <td><ejs-datepicker [disabled]="!selectedRecordsNewTonCB" showClearButton="false"
                                [(ngModel)]="selectedRecordsNewTon" strictMode="true"
                                style="display: inline-block;"></ejs-datepicker></td>
                    </tr>
                    <tr>
                        <td><ejs-checkbox cssClass="multiEditCB" [(checked)]="selectedRecordsNewTonCB"></ejs-checkbox>
                        </td>
                        <td><span style="font-weight: bold;">T-On: </span></td>
                        <td><ejs-timepicker [disabled]="!selectedRecordsNewTonCB" step="5"
                                [scrollTo]="timePickerScrollto" [format]="timePickerFormat" showClearButton="false"
                                [(ngModel)]="selectedRecordsNewTon" strictMode="true"
                                style="display: inline-block;"></ejs-timepicker>
                            <button id="tOnNowButton" class="btn btn-sm btn-primary"
                                (click)="setModalTimesNow('ton-multi')">Now</button>
                        </td>
                    </tr>
                    <tr>
                        <td><ejs-checkbox cssClass="multiEditCB" [(checked)]="selectedRecordsNewToffCB"></ejs-checkbox>
                        </td>
                        <td><span style="font-weight: bold;">T-Off: </span></td>
                        <td><ejs-timepicker [disabled]="!selectedRecordsNewToffCB" step="5"
                                [scrollTo]="timePickerScrollto" [format]="timePickerFormat" showClearButton="false"
                                [(ngModel)]="selectedRecordsNewToff" strictMode="true"
                                style="display: inline-block;"></ejs-timepicker>
                            <button id="tOnNowButton" class="btn btn-sm btn-primary"
                                (click)="setModalTimesNow('toff-multi')">Now</button>
                        </td>
                    </tr>
                    <tr>
                        <td><ejs-checkbox cssClass="multiEditCB" [(checked)]="selectedRecordsNewPowerCB"></ejs-checkbox>
                        </td>
                        <td><span style="font-weight: bold;">Power: </span></td>
                        <td><ejs-numerictextbox [enabled]="selectedRecordsNewPowerCB"
                                [(value)]="selectedRecordsNewPower"></ejs-numerictextbox></td>
                    </tr>
                    <tr>
                        <td><ejs-checkbox cssClass="multiEditCB" [(checked)]="selectedRecordsNewFrequencyCB"></ejs-checkbox>
                        </td>
                        <td><span style="font-weight: bold;">Frequency: </span></td>
                        <td><ejs-textbox [enabled]="selectedRecordsNewFrequencyCB"
                                [(value)]="selectedRecordsNewFrequency" placeholder="   MHz.KHz.Hz (0.000.000)"></ejs-textbox></td>
                    </tr>
                    <tr>
                        <td><ejs-checkbox cssClass="multiEditCB" [(checked)]="selectedRecordsNewBandCB"></ejs-checkbox>
                        </td>
                        <td><span style="font-weight: bold;">Band: </span></td>
                        <td><ejs-dropdownlist class="quick-edit-ddl" [enabled]="selectedRecordsNewBandCB" [dataSource]="allBands"
                                [(value)]="selectedRecordsNewBand" placeholder="Band"></ejs-dropdownlist></td>
                    </tr>
                    <tr>
                        <td><ejs-checkbox cssClass="multiEditCB" [(checked)]="selectedRecordsNewModeCB"></ejs-checkbox>
                        </td>
                        <td><span style="font-weight: bold;">Mode: </span></td>
                        <td><ejs-dropdownlist class="quick-edit-ddl" [enabled]="selectedRecordsNewModeCB" [dataSource]="modeList"
                                [(value)]="selectedRecordsNewMode" placeholder="Mode"></ejs-dropdownlist></td>
                    </tr>
                    <tr>
                        <td><ejs-checkbox cssClass="multiEditCB" [(checked)]="selectedRecordsNewNetCB"></ejs-checkbox>
                        </td>
                        <td><span style="font-weight: bold;">NET: </span></td>
                        <td><ejs-combobox *ngIf="this.user" [enabled]="selectedRecordsNewNetCB" class="logEntryTextbox"
                                 [fields]="{ text: 'name', value: 'name' }" [dataSource]='this.user.netPresets' [(value)]="selectedRecordsNewNet"
                                placeholder='Net Name' popupHeight='250px'></ejs-combobox></td>
                    </tr>
                    <tr>
                        <td><ejs-checkbox cssClass="multiEditCB"
                                [(checked)]="selectedRecordsNewCommentsCB"></ejs-checkbox></td>
                        <td><span style="font-weight: bold;">Comments: </span></td>
                        <td><ejs-textbox [disabled]="!selectedRecordsNewCommentsCB"
                                [(ngModel)]="selectedRecordsNewComments" [multiline]="true"
                                style="display: inline-block;" width="250"></ejs-textbox></td>
                    </tr>
                </table>


            </div>
            <div class="modal-footer">
                <div style="display: block; width: 100%;">
                    <div style="float:left; margin: auto;">
                        <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cancel</button>
                    </div>
                    <div style="float: right; margin: auto;">
                        <button type="button" class="btn btn-success btn-sm"
                            (click)="updateSelectedRows()">Save</button>
                        <button style="margin:10px;" type="button" class="btn btn-danger btn-sm"
                            (click)="deleteFromModal()">{{modalDeleteOrCancel}}</button>
                    </div>
                </div>
                <div style="display: block; width: 100%;">
                    <div *ngIf="areYouSureModalDelete" style="float: right; margin: auto;">
                        <button (click)="deleteSelectedRows()" class="btn btn-danger btn-sm">ARE YOU SURE?</button>
                    </div>
                </div>
                <div style="display: block; width: 100%;">
                    <div *ngIf="areYouSureModalDelete" style="float: right; margin: auto;">
                        <h6 class="redText" *ngIf="this.selectedRecords.length > 1">YOU ARE ABOUT TO DELETE
                            {{this.selectedRecords.length}} RECORDS </h6>
                        <h6 class="redText" *ngIf="this.selectedRecords.length == 1">YOU ARE ABOUT TO DELETE
                            {{this.selectedRecords.length}} RECORD </h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="recallNetsModal" tabindex="-1" aria-labelledby="recallNetsModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="recallNetsModalLabel">Recall Net-Log Group
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body scrollable-max-height">
                <table style="text-align: center; width:100%">
                    <tr>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Name</th>
                        <th>Check-ins</th>
                        <th>Action</th>
                    </tr>
                    <tr *ngFor="let n of parsedNetLogs">
                        <td>{{n.netDate | date: 'MM/dd/yyyy'}}</td>
                        <td>{{n.netDate | date: 'HH:mm:ss'}}</td>
                        <td>{{n.netName}}</td>
                        <td>{{n.numberOfCheckins}}</td>
                        <td><button class="btn btn-primary btn-sm" data-bs-dismiss="modal"
                                (click)="startNetRt(true, n.id, n.numberOfCheckins)">Recall</button></td>
                    </tr>
                </table>
            </div>
            <div class="modal-footer">
                <button class="btn btn-danger btn-sm" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="HamlogModal" tabindex="-1" aria-labelledby="HamlogModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="HamlogModalLabel">Log Entry: {{selectedHamlog.callsign}} - Quick Edit
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <table class="table-sm">
                    <tr>
                        <td><span style="font-weight: bold;">Callsign: </span></td>
                        <td><ejs-textbox [(ngModel)]="selectedHamlog.callsign"
                                style="display: inline-block;"></ejs-textbox>
                            <a class="btn btn-sm btn-primary" href="https://www.qrz.com/db/{{selectedHamlog.callsign}}"
                                target="_blank">Go To
                                QRZ</a>
                        </td>
                    </tr>
                    <tr>
                        <td><span style="font-weight: bold;">Name: </span></td>
                        <td><ejs-textbox [(ngModel)]="selectedHamlog.fullName"
                                style="display: inline-block;"></ejs-textbox></td>
                    </tr>
                    <tr>
                        <td><span style="font-weight: bold;">Date: </span></td>
                        <td><ejs-datepicker showClearButton="false" [(ngModel)]="selectedHamlog.dateAndTime"
                                strictMode="true" style="display: inline-block;"></ejs-datepicker></td>
                    </tr>
                    <tr>
                        <td><span style="font-weight: bold;">T-On: </span></td>
                        <td><ejs-timepicker step="5" [scrollTo]="timePickerScrollto" [format]="timePickerFormat"
                                showClearButton="false" [(ngModel)]="selectedHamlog.dateAndTime" strictMode="true"
                                style="display: inline-block;"></ejs-timepicker>
                            <button id="tOnNowButton" class="btn btn-sm btn-primary"
                                (click)="setModalTimesNow('ton')">Now</button>
                        </td>
                    </tr>
                    <tr>
                        <td><span style="font-weight: bold;">T-Off: </span></td>
                        <td><ejs-timepicker step="5" [scrollTo]="timePickerScrollto" [format]="timePickerFormat"
                                showClearButton="false" [(ngModel)]="selectedHamlog.timeOff" strictMode="true"
                                style="display: inline-block;"></ejs-timepicker>
                            <button id="tOnNowButton" class="btn btn-sm btn-primary"
                                (click)="setModalTimesNow('toff')">Now</button>
                        </td>
                    </tr>
                    <tr>
                        <td><span style="font-weight: bold;">Frequency: </span></td>
                        <td><ejs-textbox [(ngModel)]="selectedHamlog.frequency"
                                style="display: inline-block;"></ejs-textbox></td>
                    </tr>
                    <tr>
                        <td><span style="font-weight: bold;">Band: </span></td>
                        <td><ejs-dropdownlist class="quick-edit-ddl" [dataSource]="allBands" [(ngModel)]="selectedHamlog.band"
                                style="display: inline-block;"></ejs-dropdownlist></td>
                    </tr>
                    <tr>
                        <td><span style="font-weight: bold;">Mode: </span></td>
                        <td><ejs-dropdownlist class="quick-edit-ddl" [dataSource]="modeList" [(ngModel)]="selectedHamlog.mode"
                                style="display: inline-block;"></ejs-dropdownlist></td>
                    </tr>
                    <tr>
                        <td><span style="font-weight: bold;">NET: </span></td>
                        <td><ejs-combobox class="quick-edit-ddl" *ngIf="this.user" class="logEntryTextbox" style="font-weight: bold;"
                            [fields]="{ text: 'name', value: 'name' }" [dataSource]='this.user.netPresets' [(value)]="selectedHamlog.qth" placeholder='Net Name'
                                popupHeight='250px'></ejs-combobox></td>
                    </tr>
                    <tr>
                        <td><span style="font-weight: bold;">Comments: </span></td>
                        <td><ejs-textbox [(ngModel)]="selectedHamlog.comments" [multiline]="true"
                                style="display: inline-block;" width="250"></ejs-textbox></td>
                    </tr>
                </table>
            </div>
            <div class="modal-footer">
                <div style="display: block; width: 100%;">
                    <div style="float:left; margin: auto;">
                        <button style="margin: 10px;" type="button" class="btn btn-primary btn-sm"
                            routerLink="/hamlog/update/{{selectedHamlog.id}}" data-bs-dismiss="modal">More
                            Details</button>
                        <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">Close</button>
                    </div>
                    <div style="float: right; margin: auto;">
                        <button type="button" class="btn btn-success btn-sm" (click)="saveModalEdits()"
                            data-bs-dismiss="modal">Save</button>
                        <button style="margin:10px;" type="button" class="btn btn-danger btn-sm"
                            (click)="deleteFromModal()">{{modalDeleteOrCancel}}</button>
                    </div>
                </div>
                <div>
                    <button *ngIf="areYouSureModalDelete" (click)="deleteFromModalVerified()"
                        class="btn btn-danger btn-sm" data-bs-dismiss="modal">ARE YOU SURE?</button>
                </div>
            </div>
        </div>
    </div>
</div>

<ejs-toast id="toast" #toastMessageBox [position]='toastMessagePosition'>
    <ng-template #title>
        <div>{{toastMessageTitle}}</div>
    </ng-template>
    <ng-template #content>
        <div>{{toastMessageContent}}</div>
    </ng-template>
</ejs-toast>

<div class="parentcon">
    <header>
        <app-sub-menu (onClearSelectedRowsClick)="clearSelectedRows()" (onEditSelectedRowsClick)="editSelectedRows()"
            (onRecallNetlogsClick)="recallNetsListModalFire()" (onSessionTimerClick)="openSessionTimeoutModal()"
            (onSaveClick)="save()" (onRigControlControlClick)="rigControl?.openRigControlModal()"
            (onRigControlSettingsClick)="rigControl?.openSerialSettingsModal()"
            (onRigControlConnectClick)="rigControl?.connect()" [checkincount]="checkincount"
            [recordCount]="recordCount" [isNetRtMode]="isNetRtMode" [lookupPreviousLogs]="lookupPreviousLogs"
            [(showLookupPreviousLogs)]="showLookupPreviousLogs" [searching]="searching"
            [searchResMessage]="searchResMessage" [user]="user" [userProfile]="selectedUserProfile"
            pageTitle="Hamlogs"></app-sub-menu>
        <div *ngIf="operateAsGroup" class="header-warning-message">You are currently operating as {{user.operateAsGroupName}}</div>

        <!-- <button (click)="connectSerialInterface()">TEST CONNECT</button> -->
    </header>
    <!-- [dataSource]="!searching && (lookupPreviousLogs || showLookupPreviousLogs) ? searchHamLogs : hamlogs" -->
    <!-- [dataSource]="!searching && (lookupPreviousLogs || showLookupPreviousLogs) ? searchHamLogs : hamlogDataManager" -->
    
    <ejs-grid #grid id="hamlogGrid" *ngIf="selectedUserProfile"
        [dataSource]="showEmpty ? emptyDataSet : hamlogDataManager" [allowReordering]="true" [frozenColumns]="2"
        enableAltRow="true" [enableAdaptiveUI]="true" [allowResizing]="true" [allowFiltering]="true"
        [filterSettings]="filterSettings" [allowPaging]="true" [pageSettings]="pageSettings"
        [allowSorting]="true" [selectionSettings]="selectionOptions" [enablePersistence]="false"
        (actionComplete)="hamLogTableOnChangeCompleted($event)" (load)="loadNewHamLogsTable()"
        (rowDataBound)="gridRowDataBound($event)"
        (rowSelecting)="rowSelecting($event)"
        (beforeDataBound)="gridBeforeDataBound($event)" rowHeight="30" width="100%">
        <e-columns>
            <e-column field="id" headerText="More" textAlign="Center" minWidth="60" width="60" maxWidth="60"
                [isPrimaryKey]="true" [allowReordering]="false" [allowSorting]="false" >
                <ng-template #template let-hamlogData>
                    <div class="e-section-control">
                        <button ejs-button cssClass="e-info e-round e-small" [isPrimary]="true"
                            style="text-align: center;" data-bs-toggle="modal" data-bs-target="#HamlogModal"
                            (click)="hamlogInfoModalOpen(hamlogData)">+</button>
                    </div>
                </ng-template>
            </e-column>
            <e-column field='Callsign' headerText='Callsign' textAlign='Center' minWidth="85" width="85" maxWidth="85" [allowReordering]="false">
                <ng-template #template let-hamlogData>
                    <button ejs-button cssClass="e-flat" [innerText]="hamlogData.callsign"
                    (click)="callSignButtonOpen(hamlogData)"></button>
                </ng-template>
            </e-column>
            <e-column [visible]="isNetRtMode && (searching || (!lookupPreviousLogs && !showLookupPreviousLogs))" [allowReordering]="false"
                headerText='Checkout' textAlign='Center' minWidth="85" width="85" maxWidth="85">
                <ng-template #template let-hamlogData>
                    <button ejs-button cssClass="e-small btnCheckOut"
                        (click)="checkoutNetRt(hamlogData)">Checkout</button>
                    <button ejs-button cssClass="e-small btnCheckIn"
                        (click)="checkoutNetRt(hamlogData, false)">Re-Check</button>
                </ng-template>
            </e-column>
            <e-column [visible]="isNetRtMode && (searching || (!lookupPreviousLogs && !showLookupPreviousLogs))"
                headerText='OAD' textAlign='Center' minWidth="85" width="85" maxWidth="85">
                <ng-template #template let-hamlogData>
                    <ejs-checkbox *ngIf="hamlogData.netLogs" [(checked)]="hamlogData.netLogs.oneAndDone"
                        [disabled]="false" (change)="oadCheckedChanged(hamlogData)"></ejs-checkbox>
                </ng-template>
            </e-column>
            <e-column [visible]="operateAsGroup || isGroupAdmin" field='OperatorCallsign' foreignKeyField="OperatorCallsign" headerText='Operator' textAlign="Center" minWidth="90" width="90"
            maxWidth="300"></e-column>
            <e-column [visible]="operateAsGroup || isGroupAdmin" field='OperatorName' foreignKeyField="OperatorName" headerText='Operator Name' textAlign="Center" minWidth="200" width="200"
            maxWidth="300"></e-column>
            <e-column field='FullName' foreignKeyField="FullName" headerText='Name' textAlign="Center" minWidth="200" width="200"
                maxWidth="300"></e-column>
            <e-column [visible]="showNetColumn" field='Qth' foreignKeyField="Qth" headerText="NET" textAlign="Center" width="200"
                minWidth="65" maxWidth="300"></e-column>
            <e-column field='Frequency' foreignKeyField="Frequency" headerText='Frequency' textAlign='Center' minWidth="90" maxWidth="90"
                width="90"></e-column>
            <e-column field='band' headerText='Band' textAlign='Center' [filterBarTemplate]="templateOptionsDropDown"
                minwidth="60" width="60" maxWidth="60">
            </e-column>
            <e-column field='Mode' headerText='Mode' textAlign='Center' minwidth="65" width="65"
                maxWidth="65"></e-column>
            <e-column field='Power' headerText='Power' textAlign='Center' minwidth="65" width="65"
                maxWidth="65"></e-column>
            <e-column field='City' headerText='City' textAlign='Center' minWidth="100" width="100"
                maxWidth="200"></e-column>
            <e-column field='State' headerText='State' textAlign='Center' minWidth="65" width="65"
                maxWidth="65"></e-column>
            <e-column field='DateAndTime' headerText='Date' textAlign='Center' type="date"
                [filterBarTemplate]="templateOptionsDatePicker" [format]="dateFormatOptions" minwidth="75" width="80"
                maxWidth="85"></e-column>
            <e-column field='DateAndTime' headerText='T-On' textAlign='Center' type="date" [format]="timeFormatOptions"
                minwidth="75" width="80" maxWidth="85"></e-column>
            <e-column [visible]="!isNetRtMode" field='TimeOff' headerText='T-Off' textAlign='Center' type="date"
                [format]="timeFormatOptions" minwidth="75" width="80" maxWidth="85"></e-column>
            <e-column [visible]="isNetRtMode" field='NetLogs.offTime' headerText='T-Off' textAlign='Center' type="date"
                [format]="timeFormatOptions" minwidth="75" width="80" maxWidth="85"></e-column>
            <e-column field='Comments' headerText="Comments" textAlign="left" width="350" maxWidth="500"></e-column>
            <e-column field="Cont" headerText="Country" textAlign="Center" minWidth="50" width="50" maxWidth="50">
                <ng-template #template let-data>
                    {{getCoutryCodes(data.country)}}
                </ng-template>
            </e-column>
            <e-column field="Country" headerText="Flag" textAlign="Center" minWidth="40" width="40" maxWidth="40">
                <ng-template #template let-data>
                    <img class="table-country-image" *ngIf="hasFlagInData(getCoutryCodes(data.country))" alt="{{data.country}}"
                        src="assets/images/country-flag-icons/3x2/{{getCoutryCodes(data.country)}}.svg" />
                </ng-template>
            </e-column>
            <e-column field="Class" headerText="Class" textAlign="Center" minWidth="60" width="60"
                maxWidth="60"></e-column>
            <e-column field="Email" headerText="Email" textAlign="Center" minWidth="60" width="150" maxWidth="200">
                <ng-template #template let-data>
                    <a href="mailto:{{data.email}}" (click)="emailLinkClicked()">{{data.email}}</a>
                </ng-template>
            </e-column>
            <e-column field="Grid" headerText="Grid" textAlign="Center" minWidth="60" width="60"
                maxWidth="60"></e-column>
            <e-column field="Lat" headerText="Lat" textAlign="Center" minWidth="60" width="60" maxWidth="60"></e-column>
            <e-column field="Lon" headerText="Lon" textAlign="Center" minWidth="60" width="60" maxWidth="60"></e-column>
            <e-column field="LookupSource" headerText="Source" textAlign="Center" minWidth="75" width="75"
                maxWidth="75"></e-column>
        </e-columns>


    </ejs-grid>
    <form #aForm>
        <div class="mobile-visible">
            <div class="flex-containerRow" style="width: 100%;">
                <table class="logEntryTable">
                    <tr>
                        <td class="logEntryTablePerm">GMT</td>
                    </tr>
                    <tr>
                        <td class="logEntryTableData"><ejs-textbox name="gmtTime" class="logEntryTextbox"
                                [tabIndex]="-1" style="font-weight: bold;" [(ngModel)]="nowGMT"
                                readonly="true"></ejs-textbox></td>
                    </tr>
                </table>
                <table class="logEntryTable">
                    <tr>
                        <td class="logEntryTablePerm">Local</td>
                    </tr>
                    <tr>
                        <td class="logEntryTableData"><ejs-textbox name="currenttime" class="logEntryTextbox"
                                [tabIndex]="-1" style="font-weight: bold;" [(ngModel)]="now"
                                readonly="true"></ejs-textbox></td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="flex-containerRow" style="width: 100%;">
            <table class="logEntryTable">
                <tr>
                    <td class="logEntryTableHead">Call</td>
                </tr>
                <tr>
                    <td class="logEntryTableData"><ejs-textbox name="callsign" class="logEntryTextbox"
                            style="font-weight: bold;" [(ngModel)]="newlog.callsign" (blur)="search()"
                            (focus)="clear(false)"></ejs-textbox></td>
                </tr>
            </table>
            <table class="logEntryTable">
                <tr>
                    <td class="logEntryTableHead">Frequency</td>
                </tr>
                <tr>
                    <td class="logEntryTableData"><ejs-textbox name="frequency" class="logEntryTextbox"
                            style="font-weight: bold;" [(ngModel)]="enteredfreq" (ngModelChange)="freqChange()"
                            (blur)="freqBlur()"></ejs-textbox></td>
                </tr>
            </table>
            <table class="logEntryTable" width="75px">
                <tr>
                    <td class="logEntryTableHead">Band</td>
                </tr>
                <tr>
                    <td class="logEntryTableData"><ejs-textbox name="band" class="logEntryTextbox"
                            style="font-weight: bold;" [(ngModel)]="newlog.band"></ejs-textbox></td>
                </tr>
            </table>
            <table class="logEntryTable" width="75px">
                <tr>
                    <td class="logEntryTableHead">Mode</td>
                </tr>
                <tr>
                    <td class="logEntryTableData"><ejs-dropdownlist name="mode" id="mainEntryddl"
                            [dataSource]="modeList" [(value)]="newlog.mode"></ejs-dropdownlist></td>
                </tr>
            </table>
            <table class="logEntryTable" width="75px">
                <tr>
                    <td class="logEntryTableHead">Power</td>
                </tr>
                <tr>
                    <td class="logEntryTableData"><ejs-textbox name="power" class="logEntryTextbox"
                            style="font-weight: bold;" [(ngModel)]="newlog.power"></ejs-textbox></td>
                </tr>
            </table>
            <table class="logEntryTable" width="75px">
                <tr>
                    <td class="logEntryTableHead">Sent</td>
                </tr>
                <tr>
                    <td class="logEntryTableData"><ejs-textbox name="sent" class="logEntryTextbox"
                            style="font-weight: bold;" [(ngModel)]="newlog.rsTsent"></ejs-textbox></td>
                </tr>
            </table>
            <table class="logEntryTable" width="75px">
                <tr>
                    <td class="logEntryTableHead">Rec</td>
                </tr>
                <tr>
                    <td class="logEntryTableData"><ejs-textbox name="rec" class="logEntryTextbox"
                            style="font-weight: bold;" [(ngModel)]="newlog.rsTrec"></ejs-textbox></td>
                </tr>
            </table>
            <table class="timeAndDate logEntryTable">
                <tr>
                    <td class="logEntryTableHead">T-ON</td>
                </tr>
                <tr>
                    <td class="logEntryTableData"><ejs-timepicker [format]="timePickerFormat" [tabIndex]="-1"
                            name="tontime" step="1" strictMode="true" id="entryTimePicker" style="font-weight: bold;"
                            [(ngModel)]="newlog.dateAndTime"></ejs-timepicker></td>
                </tr>
            </table>
            <table class="timeAndDate logEntryTable">
                <tr>
                    <td class="logEntryTableHead">Date</td>
                </tr>
                <tr>
                    <td class="logEntryTableData" style="font-weight: bold; text-align: center;"><ejs-datepicker
                            [tabIndex]="-1" name="tondate" id="entryDatePicker" style="font-weight: bold;"
                            strictMode="true" [(ngModel)]="newlog.dateAndTime"></ejs-datepicker></td>
                </tr>
            </table>
            <table class="desktop-visible logEntryTable" style="justify-content: right; margin-left: auto;">
                <tr>
                    <td class="logEntryTablePerm">GMT</td>
                </tr>
                <tr>
                    <td class="logEntryTableData"><ejs-textbox name="gmtTime" class="logEntryTextbox" [tabIndex]="-1"
                            style="font-weight: bold;" [(ngModel)]="nowGMT" readonly="true"></ejs-textbox></td>
                </tr>
            </table>
        </div>
        <div class="flex-containerRow2" style="width: 100%;">
            <table class="logEntryTable">
                <tr>
                    <td class="logEntryTableHead">Name</td>
                </tr>
                <tr>
                    <td class="logEntryTableData"><ejs-textbox name="name" class="logEntryTextbox"
                            style="font-weight: bold;" [(ngModel)]="newlog.fullName"></ejs-textbox></td>
                </tr>
            </table>
            <table class="logEntryTable">
                <tr>
                    <td class="logEntryTableHead">City</td>
                </tr>
                <tr>
                    <td class="logEntryTableData"><ejs-textbox name="city" class="logEntryTextbox"
                            style="font-weight: bold;" [(ngModel)]="newlog.city"></ejs-textbox></td>
                </tr>
            </table>
            <table class="stateField logEntryTable">
                <tr>
                    <td class="logEntryTableHead">State</td>
                </tr>
                <tr>
                    <td class="logEntryTableData"><ejs-textbox name="state" class="logEntryTextbox"
                            style="font-weight: bold;" [(ngModel)]="newlog.state"></ejs-textbox></td>
                </tr>
            </table>
            <table class="logEntryTable">
                <tr>
                    <td class="logEntryTableHead">Zip</td>
                </tr>
                <tr>
                    <td class="logEntryTableData"><ejs-textbox name="zipcode" class="logEntryTextbox"
                            style="font-weight: bold;" [(ngModel)]="newlog.postalCode"></ejs-textbox></td>
                </tr>
            </table>
            <table class="logEntryTable">
                <tr>
                    <td class="logEntryTableHead">Country</td>
                </tr>
                <tr>
                    <td class="logEntryTableData"><ejs-textbox name="country" class="logEntryTextbox country-box"
                            style="font-weight: bold;" [(ngModel)]="newlog.country"></ejs-textbox>
                        <img id="country-img" *ngIf="hasFlagInData(getCoutryCodes(newlog.country))"
                            alt="{{newlog.country}}"
                            src="assets/images/country-flag-icons/3x2/{{getCoutryCodes(newlog.country)}}.svg" />
                    </td>
                </tr>
            </table>
            <table class="logEntryTable">
                <tr>
                    <td class="logEntryTableHead">Net/Contest</td>
                </tr>
                <tr>
                    <td class="logEntryTableData"><ejs-combobox *ngIf="this.user" id='netcontest' class="logEntryTextbox"
                            style="font-weight: bold;" (select)="netPresetSelected($event)" [fields]="{ text: 'name', value: 'name' }" [dataSource]='this.user.netPresets' [(value)]="newlog.qth"
                            placeholder='Net Name' popupHeight='250px'></ejs-combobox></td>
                </tr>
            </table>
            <table *ngIf="isNetRtMode">
                <tr>
                    <td style="font-weight: bold;">OAD:</td>
                    <td><ejs-checkbox [(checked)]="netlog.oneAndDone"></ejs-checkbox></td>
                </tr>
            </table>
            <table class="desktop-visible logEntryTable" style="justify-content: right; margin-left: auto;">
                <tr>
                    <td class="logEntryTablePerm">Local</td>
                </tr>
                <tr>
                    <td class="logEntryTableData"><ejs-textbox name="currenttime" class="logEntryTextbox"
                            [tabIndex]="-1" style="font-weight: bold;" [(ngModel)]="now" readonly="true"></ejs-textbox>
                    </td>
                </tr>
            </table>
        </div>
        <div class="flex-containerRow3" style="width: 100%;">
            <table class="logEntryTable">
                <tr>
                    <td class="logEntryTableHead">Comments</td>
                </tr>
                <tr>
                    <td class="logEntryTableData"><ejs-textbox name="comments" [multiline]="true"
                            style="font-weight: bold;" [(ngModel)]="newlog.comments" (focus)="enterKeyDisable(true)"
                            (blur)="enterKeyDisable(false)"></ejs-textbox></td>
                </tr>
            </table>
        </div>
    </form>

    <div id="save">
        <!-- <button tabindex="-1" style="margin: 10px" (click)="togglePreviousLogs()" id="searchbtn" class="btn btn-primary btn-sm">Toggle Previous</button> -->
        <button *ngIf="!isNetRtMode" class="btn btn-sm btn-primary" style="margin-left: 10px;"
            (click)="startNetRt(false)">Start NET/RT</button>
        <button *ngIf="isNetRtMode" class="btn btn-sm btn-danger" style="margin-left: 10px;" (click)="endNetRt()">End
            NET/RT</button>
        <button style="margin: 10px;" (click)="save()" (focus)="enterKeyDisable(true)" (blur)="enterKeyDisable(false)"
            class="btn btn-success btn-sm">Log Contact</button>
        <!-- <button (click)="testQRZ()">QRZ</button> -->
        <span id="WARN">{{warnMessage}} {{warnMessageDesc}}</span>
        <!-- <span id="success" *ngIf="logpost">{{successMessage}}</span> -->
    </div>
    <app-dxspot *ngIf="showDxSpot && user" [tableHeight]="300"></app-dxspot>

</div>
<app-serial-interface #rigcontrol *ngIf="selectedUserProfile && selectedUserProfile.rigControlEnabled"
    [(radioCurrentFrequency)]="enteredfreq" [(radioCurrentBand)]="newlog.band" [(radioCurrentMode)]="newlog.mode"
    [profileId]="selectedUserProfile.id"></app-serial-interface>