import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../user.class';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginCredentials } from '../logincredentials.class';
import { AdditionalData } from '../additionaldata.class';


@Injectable({
  providedIn: 'root'
})
export class VisitorLogService {

  // baseurl: string = 'http://localhost:5000/api/users';
  baseurl: string = environment.CalllogAPIurl + '/api/visitorslog';
  appkey: string = environment.AppKey;

  constructor(
    private http: HttpClient,

  ) { }

  add(log: AdditionalData): Observable<User> {
    return this.http.post(`${this.baseurl}/add`, log, { headers: { ['XAppKey']: `${this.appkey}` } }) as Observable<User>;
  }

  getUserIP(): Observable<any> {
    return this.http.get('https://geolocation-db.com/json/') as Observable<any>;
  }


}