<div id="component">
    <app-menu></app-menu>
    <app-sub-menu (onSessionTimerClick)="openSessionTimeoutModal()" (onSaveClick)="save()" [user]="user"
        [pageTitle]="pageTitle" [searching]="true"></app-sub-menu>
    <app-time-out-modal></app-time-out-modal>
    <header>

        <!-- <span id="pageTitle">{{pageTitle}}</span> -->
        <a *ngIf="hamlog" routerLink="/logging/newlog/{{hamlog.userId}}" class="btn btn-primary btn-sm"
            style="margin:5px; margin-left: 10px;">Back</a>
        <button (click)="save()" class="btn btn-success btn-sm" style="margin: 5px;">Save</button>
        <span style="font-weight: bold;">Message Center: </span>
        <input *ngIf="reportError" style="color:red;" readonly [value]="reportToUser" [size]="myInput.value.length"
            #myInput>
        <input *ngIf="!reportError" style="color:green;" readonly [value]="reportToUser" [size]="myInput.value.length"
            #myInput>

    </header>
    <main>

        <table *ngIf="hamlog">
            <tr>
                <td><span style="font-weight: bold;">Log Id: </span></td>
                <td><ejs-textbox style="display: inline-block;" [ngModel]="hamlog.id" readonly
                        disabled="true"></ejs-textbox></td>
            </tr>


            <tr>
                <td><span style="font-weight: bold;">Callsign: </span></td>
                <td><ejs-textbox style="display: inline-block;" [(ngModel)]="hamlog.callsign"></ejs-textbox></td>
                <td><button class="btn btn-primary btn-sm" (click)="searchLocalFccData()"
                        style="margin-top:10px;">Lookup</button><span
                        style="color:red; float:right; padding-left: 10px;"> Click here to populate available fields
                        from database.<br>Note: This will REPLACE the data currently shown.</span></td>
            </tr>


            <tr>
                <td><span style="font-weight: bold;">Full Name: </span></td>
                <td><ejs-textbox style="display: inline-block;" [(ngModel)]="hamlog.fullName"></ejs-textbox></td>
            </tr>


            <tr>
                <td><span style="font-weight: bold;">FCC Id: </span></td>
                <td><ejs-textbox style="display: inline-block;" [(ngModel)]="hamlog.fccId"></ejs-textbox></td>
            </tr>


            <tr>
                <td><span style="font-weight: bold;">First Name: </span></td>
                <td><ejs-textbox style="display: inline-block;" [(ngModel)]="hamlog.firstName"></ejs-textbox></td>
                <td style="color: red"> Note: Edit Full Name for information displayed in Hamlogs. </td>
            </tr>


            <tr>
                <td><span style="font-weight: bold;">Last Name: </span></td>
                <td><ejs-textbox style="display: inline-block;" [(ngModel)]="hamlog.lastName"></ejs-textbox></td>
            </tr>


            <tr>
                <td><span style="font-weight: bold;">Frequency: </span></td>
                <td><ejs-textbox style="display: inline-block;" [(ngModel)]="hamlog.frequency"></ejs-textbox></td>
            </tr>
            
            <tr>
                <td><span style="font-weight: bold;">Band: </span></td>
                <td><ejs-dropdownlist style="display: inline-block;" [dataSource]="allBands" [(ngModel)]="hamlog.band"></ejs-dropdownlist></td>
            </tr>


            <tr>
                <td><span style="font-weight: bold;">Mode: </span></td>
                <td><ejs-dropdownlist style="display: inline-block;" [dataSource]="modeList" [(value)]="hamlog.mode">
                    </ejs-dropdownlist></td>
            </tr>


            <tr>
                <td><span style="font-weight: bold;">Power: </span></td>
                <td><ejs-textbox style="display: inline-block;" [(ngModel)]="hamlog.power"></ejs-textbox></td>
            </tr>


            <tr>
                <td><span style="font-weight: bold;">RST Sent: </span></td>
                <td><ejs-textbox style="display: inline-block;" [(ngModel)]="hamlog.rsTsent"></ejs-textbox></td>
            </tr>


            <tr>
                <td><span style="font-weight: bold;">RST rcv: </span></td>
                <td><ejs-textbox style="display: inline-block;" [(ngModel)]="hamlog.rsTrec"></ejs-textbox></td>
            </tr>


            <tr>
                <td><span style="font-weight: bold;">NET/Contest: </span></td>
                <td><ejs-textbox style="display: inline-block;" [(ngModel)]="hamlog.qth"></ejs-textbox></td>
            </tr>


            <tr>
                <td><span style="font-weight: bold;">T-On: </span></td>
                <td><ejs-timepicker step="1" [scrollTo]="timePickerScrollto" [format]="timeFormat"
                        showClearButton="false" [(ngModel)]="hamlog.dateAndTime" strictMode="true"
                        style="display: inline-block;"></ejs-timepicker></td>
            </tr>

            <tr>
                <td><span style="font-weight: bold;">T-Off: </span></td>
                <td><ejs-timepicker step="1" [scrollTo]="timePickerScrollto" [format]="timeFormat"
                        showClearButton="false" [(ngModel)]="hamlog.timeOff" strictMode="true"
                        style="display: inline-block;"></ejs-timepicker></td>
            </tr>

            <tr>
                <td><span style="font-weight: bold;">Date: </span></td>
                <td><ejs-datepicker showClearButton="false" [(ngModel)]="hamlog.dateAndTime" strictMode="true"
                        style="display: inline-block;"></ejs-datepicker></td>
            </tr>


            <tr>
                <td><span style="font-weight: bold;">Address: </span></td>
                <td><ejs-textbox style="display: inline-block;" [(ngModel)]="hamlog.address"></ejs-textbox></td>
            </tr>


            <tr>
                <td><span style="font-weight: bold;">City: </span></td>
                <td><ejs-textbox style="display: inline-block;" [(ngModel)]="hamlog.city"></ejs-textbox></td>
            </tr>


            <tr>
                <td><span style="font-weight: bold;">State: </span></td>
                <td><ejs-textbox style="display: inline-block;" [(ngModel)]="hamlog.state"></ejs-textbox></td>
            </tr>


            <tr>
                <td><span style="font-weight: bold;">Zip: </span></td>
                <td><ejs-textbox style="display: inline-block;" [(ngModel)]="hamlog.postalCode"></ejs-textbox></td>
            </tr>


            <tr>
                <td><span style="font-weight: bold;">Alternate Callsign: </span></td>
                <td><ejs-textbox style="display: inline-block;" [(ngModel)]="hamlog.altCallsign"></ejs-textbox></td>
            </tr>


            <tr>
                <td><span style="font-weight: bold;">Call Modifier: </span></td>
                <td><ejs-textbox style="display: inline-block;" [(ngModel)]="hamlog.callModifier"></ejs-textbox></td>
            </tr>


            <tr>
                <td><span style="font-weight: bold;">Reciprical: </span></td>
                <td><ejs-textbox style="display: inline-block;" [(ngModel)]="hamlog.reciprical"></ejs-textbox></td>
            </tr>


            <tr>
                <td><span style="font-weight: bold;">Comments: </span></td>
                <td colspan="3"><ejs-textbox style="display: inline-block;" [(ngModel)]="hamlog.comments"
                        multiline="true"></ejs-textbox></td>
            </tr>

        </table>


    </main>
</div>