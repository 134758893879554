import { FT1000MP } from "../radios/ft-1000mp";
import { serialDefualts } from "../radios/program-defaults";
import { YaesuNewer } from "../radios/yaesu-newer";
import { SerialOptions } from "./serial/serialOptions";

export class radioSelector {


    
    constructor(){
    }  

    selectRadio(cat: string, radio: string, serialOptions?: SerialOptions) {
        let serial!: SerialOptions;
        if(serialOptions){
            serial = serialOptions;
        }
        else{
            serial = serialDefualts[cat][radio];
        }
        switch(cat){
            case "ft1000mp":
                return new FT1000MP(serial);
            case "yaesu-newer":
                return new YaesuNewer(serial);
            default:
                return null;
        }
    }
}