import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CurrentTask } from "../classes/currenttask.class";

@Injectable({
    providedIn: 'root'
})

export class CurrentTaskService {

    baseurl: string = environment.CalllogAPIurl + '/api/currenttasks';

    constructor(
        private http: HttpClient,
    ) { }

    getTasks(id: string): Observable<CurrentTask[]> {
        return this.http.get(`${this.baseurl}/${id}`, { headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<CurrentTask[]>;
    }
    killTask(id: number): Observable<any> {
        return this.http.delete(`${this.baseurl}/${id}`, { headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<any>;
    }


}