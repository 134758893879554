import { Component } from '@angular/core';
import { encrypt } from '../dsi-encrypt';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NewUserRequestForm, PasswordResetRequest } from 'src/app/user/requests.class';
import { UserService } from '../services/user.service';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';
import { ActivatedRoute } from '@angular/router';
import { confirmPasswordValidator, passwordRules } from '../custom-validators.validator';
import { AdditionalData } from '../additionaldata.class';

@Component({
  selector: 'app-request-account',
  templateUrl: './request-account.component.html',
  styleUrl: './request-account.component.css'
})
export class RequestAccountComponent {

  resetForm!: FormGroup;
  newUserRequestForm!: FormGroup;
  routeParam: string = "";
  message: string = "";
  submissionCompleted: boolean = false;
  buttonDisable: boolean = false;

  password1: FormControl = new FormControl(null, [
    (c: AbstractControl) => Validators.required(c),
                            Validators.pattern(passwordRules)]);
  password2: FormControl = new FormControl(null, [
    (c: AbstractControl) => Validators.required(c),
                            Validators.pattern(passwordRules)]);
  callsign: FormControl = new FormControl('', [Validators.required]);
  username: FormControl = new FormControl('', [Validators.required]);
  email: FormControl = new FormControl('', [Validators.required, Validators.email]);

  clientData!: AdditionalData;
                     

  constructor(
    private _usersvc: UserService,
    private formbuilder: FormBuilder,
    private recaptchaV3Service: ReCaptchaV3Service,
    private route: ActivatedRoute
  ){
    this.resetForm = this.formbuilder.group({
      username: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email])
    });
    this.newUserRequestForm = this.formbuilder.group({
      email: this.email,
      callsign: this.callsign,
      username: this.username,
      password1: this.password1,
      password2: this.password2
    },{ validators: confirmPasswordValidator.bind(this) });
  }

  validateCaptcha() {
    this.buttonDisable = true;
    this.recaptchaV3Service.execute('importantAction')
    .subscribe({
      next: (res) => {
        this.submitReset(res);
      },
      error: (err) => {
        console.error(err);
        this.message = "Recaptcha could not be validated.";
      }
    });
  }

  submitReset(token) {
    if(this.routeParam == "reset"){
      let data: PasswordResetRequest = new PasswordResetRequest(
        this.resetForm.value.username,
        this.resetForm.value.email,
        token
      );
      this._usersvc.passwordResetRequest(data).subscribe({
        next: (res) => {
          this.submissionCompleted = res.isSuccess;
        },
        error: (err) => {
          console.error(err);
          if(typeof err.error == "object"){
            this.message = err.error.title;
          }
          if(typeof err.error == "string"){
            this.message = err.error;
          }
          this.buttonDisable = false;
        }
      });
    }
    if(this.routeParam == "signup"){
      let newPassword = encrypt(this.newUserRequestForm.value.password2);
      let data: NewUserRequestForm = new NewUserRequestForm(
        this.newUserRequestForm.value.email,
        this.newUserRequestForm.value.callsign.toUpperCase(),
        this.newUserRequestForm.value.username,
        newPassword,
        JSON.stringify(this.clientData),
        token
      );
      this._usersvc.newUserRequest(data).subscribe({
        next: (res) => {
          this.submissionCompleted = res.isSuccess;
        },
        error: (err) => {
          console.error(err);
          if(typeof err.error == "object"){
            this.message = err.error.title;
          }
          if(typeof err.error == "string"){
            this.message = err.error;
          }
          this.buttonDisable = false;
        }
      });
    }
  }
  getClientData() {
    this._usersvc.getUserIP().subscribe({
      next: (res) => {
        this.clientData = res;
      }
    })
  }

  ngOnInit() {
    this.routeParam = this.route.snapshot.params["type"];
    this.getClientData();
  }
}
