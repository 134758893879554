import { Hamlog } from "../hamlog/hamlog.class";
import { NetPreset } from "../settings/netpresets/netpreset.class";
import { UserSetting } from "./userSetting.class";
import { DefaultGuid } from "../settings/globalVars";

export class User{
    id: string = DefaultGuid;
    selectedSettingsProfileId: string = DefaultGuid;
    isAdmin: boolean = false;
    userCtrlLevel: string = "";
    qrzLookupEnabled: boolean = false;
    firstName: string = "";
    lastName: string = "";
    userName: string = "";
    password: string = "";
    callsign: string = "";
    email: string = "";
    address: string = "";
    city: string = "";
    state: string = "";
    zip: string = ""
    dateCreated: string | Date | null = null;
    dateLastModified: string | Date | null = null;
    dateLastLogin: string | Date | null = null;
    recordCount: number = 0;
    status: string = "NEW";
    qrzUserName: string = "";
    qrzPassword: string = "";
    lastWorkedMode: string = "";
    lastWorkedPower: number = 0;
    hamLogs!: Hamlog[];
    userSettings!: UserSetting[];
    netPresets!: NetPreset[];
    isDisabled: boolean = false;
    emailValidated: boolean = false;
    phone: string = "";
    isGroupAdmin: boolean = false;
    groupName: string = "";
    operateAsGroupId: string | null = null;
    operateAsGroupName: string | null = null;
    operateAsGroup: boolean = false;
    xApiKey : string = "";
}