//import { NONE_TYPE } from "@angular/compiler";
import { SerialOptions } from "./serialOptions";

export class NgxSerial {
    private ports: any;
    private port: any;
    private options = new SerialOptions; //Default
    private writer: any;
    private readFunction: Function;
    private controlCharacter: string = "\n";
    private readDataTextEncoding: boolean = false;
    private writeDataTextEncoding: boolean = false;
    private reader: any;
    private readableStreamClosed: any;
    private writableStreamClosed: any;
    private keepReading: boolean = true;

    constructor(readFunction: Function, options?: SerialOptions, readDataTextEncoding?: boolean, writeDataTextEncoding?: boolean, controlCharacter?: any) {
        this.readFunction = readFunction;
        if (options)
            this.options = options;
        if (controlCharacter)
            this.controlCharacter = controlCharacter;
        if (readDataTextEncoding)
            this.readDataTextEncoding = readDataTextEncoding;
        if (writeDataTextEncoding)
            this.writeDataTextEncoding = writeDataTextEncoding;

    }
    public async sendData(data: string | Uint8Array) {
        await this.writer.write(data);        
    }

    private async readLoop() {
        if (this.readDataTextEncoding) {
            while (this.port.readable && this.keepReading) {
                const textDecoder = new TextDecoderStream();
                this.readableStreamClosed = this.port.readable.pipeTo(textDecoder.writable);
                this.reader = textDecoder.readable
                    .pipeThrough(new TransformStream(new LineBreakTransformer(this.controlCharacter)))
                    .getReader();

                try {
                    while (true) {
                        const { value, done } = await this.reader.read();
                        if (done) {
                            break;
                        }
                        if (value) {
                            this.readFunction(value);
                        }
                    }
                } catch (error) {
                    console.error("Read Loop error. Have the serial device been disconnected ? ");
                }
            }
        }
        else {
            while (this.port.readable && this.keepReading) {
                this.reader = this.port.readable.getReader();
                try {
                    while (true) {
                        const { value, done } = await this.reader.read();
                        if (done) {
                            // Allow the serial port to be closed later.
                            this.reader.releaseLock();
                            break;
                        }
                        if (value) {
                            this.readFunction(value);
                        }
                    }
                } catch (error) {
                    // TODO: Handle non-fatal read error.
                    console.error("Read Loop error. Have the serial device been disconnected ? ");
                } finally {
                    this.reader.releaseLock();
                }
            }
        }
    }
    public async close(callback: Function) {
        this.keepReading = false;
        await this.reader.cancel();
        if (this.readDataTextEncoding) {
            await this.readableStreamClosed.catch(() => { });
        }
        if(this.writeDataTextEncoding){
            this.writer.close();
            await this.writableStreamClosed;
        }
        else{
            this.writer.releaseLock();
        }
        await this.port.close();
        callback(null);
    }

    public async connect(callback: Function) {
        this.keepReading = true;
        if ("serial" in navigator) {
            // The Web Serial API is supported by the browser.
            let nav: any = navigator;
            const ports = await nav.serial.getPorts();
            //console.log(ports);
            try {
                this.port = await nav.serial.requestPort();
            } catch (error) {
                console.error("Requesting port error: " + error);
                return;
            }

            try {
                await this.port.open(this.options);


            } catch (error) {
                console.error("Opening port error: " + error);
                return;
            }
            if(this.writeDataTextEncoding){
                const textEncoder = new TextEncoderStream();
                this.writableStreamClosed = textEncoder.readable.pipeTo(this.port.writable);
                this.writer = textEncoder.writable.getWriter();
            }
            else {
                this.writer = this.port.writable.getWriter();
            }

            this.readLoop();

            callback(this.port);

        } else {
            console.error("This browser does NOT support the Web Serial API");
        }
    }
}

class LineBreakTransformer {
    container: any = "";
    private controlCharacter: string;

    constructor(controlCharacter: string) {
        this.container = '';
        this.controlCharacter = controlCharacter
    }

    transform(chunk: any, controller: any) {
        this.container += chunk;
        const lines = this.container.split(this.controlCharacter);
        this.container = lines.pop();
        lines.forEach((line: any) => controller.enqueue(line));
    }

    flush(controller: any) {
        controller.enqueue(this.container);
    }
}