
import { Injectable } from '@angular/core';
import { User } from '../user.class';
import { UserSetting } from '../userSetting.class';
import { DefaultGuid, LogURLs } from 'src/app/settings/globalVars';


@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  public isLoggedin = false; 
  user!: User ;
  userId: string = DefaultGuid; 
  groupId: string = DefaultGuid;
  isAdmin: boolean = false; 
  isGroupAdmin: boolean = false;
  userCtrlLevel: string = "";
  username: string = "";
  selectedProfile!: UserSetting;
  lastURL: string|null = sessionStorage.getItem('pURL');
  instanceId: string = "";
  logURL: string = LogURLs["master"];

  constructor() {}

  testUserData(pURL) {
    //this.userId = this.user?.id;
    sessionStorage.setItem('pURL', pURL);
    if(this.isLoggedin == false && sessionStorage.getItem('isLoggedIn') == 'true'){
      this.isLoggedin = true;
      if(sessionStorage.getItem('isAdmin') == 'true'){
        this.isAdmin = true;
      }
      if(sessionStorage.getItem('userId') != null){
        let newuserData = sessionStorage.getItem('userId');
        this.userId = newuserData == null ? "" : newuserData;
      }
    }
  }

}