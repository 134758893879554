import { Component, EventEmitter, Output } from '@angular/core';
import { CurrentTask } from 'src/app/user/classes/currenttask.class';
import { CurrentTaskService } from 'src/app/user/services/currenttask.service';
import { LoggerService } from 'src/app/user/services/logger.service';
import { clearInterval, setInterval } from 'worker-timers';


@Component({
  selector: 'app-task-checker',
  templateUrl: './task-checker.component.html',
  styleUrl: './task-checker.component.css'
})
export class TaskCheckerComponent {

  @Output() onHamlogTask = new EventEmitter();
  @Output() onNewNotification = new EventEmitter();


  intervalId: any;
  tasks: CurrentTask[] = [];

  constructor(
    private _tasks: CurrentTaskService,
    private _sys: LoggerService,
  ) { }

  emit() {
    this.tasks.forEach((task) => {
      switch (task.name) {
        case "Hamlogs":
          if (task.instanceId == this._sys.instanceId) {
            this.onHamlogTask.emit();
            this.killTask(task.id);
          }
          break;
        case "Notifications":
          this.onNewNotification.emit();
          this.killTask(task.id);
          break;
      }
    });
  }

  killTask(id) {
    this._tasks.killTask(id).subscribe({
      next: (res) => {
      }
    });
  }

  getTasks() {
    this._tasks.getTasks(this._sys.userId).subscribe({
      next: (res) => {
        this.tasks = res;
        this.emit();
      }
    });
  }

  refresh() {
    if (this._sys.isLoggedin) {
      this.intervalId = setInterval(() => {
        this.getTasks();
      }, 5000);
    }
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  ngOnInit() {
    this.refresh();
  }
}
