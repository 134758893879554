import { QrzService } from "src/app/user/services/qrz.service";
import { Hamlog } from "../hamlog.class";
import { qrzSubscriber } from "src/app/user/qrzSubscriber.class";
import { qrzNonSub } from "src/app/user/qrzNonSubscriber.class";

export class SearchQRZ {

    newlog!: Hamlog;
    qrzNotFound: boolean = false;
    qrzSub: boolean = false;
    qrzNonSub: qrzNonSub = new qrzNonSub;
    qrzSubscriber: qrzSubscriber = new qrzSubscriber;

    constructor(
        private qrz: QrzService,
        newlog: Hamlog
    ) {
        this.newlog = newlog;
    }


    searchQRZ() {
        this.qrz.searchQrz(this.newlog.callsign).subscribe({
            next: (res) => {
                if (res.QRZDatabase.Session.Error === `Not found: ${this.newlog.callsign.toUpperCase()}`) {
                    this.qrzNotFound = true;
                    console.warn("QRZ " + res.QRZDatabase.Session.Error);
                    return;
                }
                if (res.QRZDatabase.Session != null) {
                    if (res.QRZDatabase.Session.Error != null) {
                        this.qrz.qrzLogin(sessionStorage.getItem('qrzu'), sessionStorage.getItem('qrzp')).subscribe({
                            next: (res) => {
                                if (res.QRZDatabase.Session.Error != null) {
                                    return;
                                }
                                else {
                                    if (res.QRZDatabase.Session != null) {
                                        sessionStorage.setItem('qrzSessionKey', res.QRZDatabase.Session.Key);
                                        this.qrz.searchQrz(this.newlog.callsign).subscribe({
                                            next: (res) => {
                                                if (res.QRZDatabase.Session.Error != null) {
                                                    if (res.QRZDatabase.Session.Error == `Not found: ${this.newlog.callsign}`) {
                                                        console.warn("QRZ " + res.QRZDatabase.Session.Error);
                                                        this.qrzNotFound = true;
                                                        return;
                                                    }
                                                }
                                                else {
                                                    if (res.QRZDatabase.Session.SubExp == "non-subscriber") {
                                                        console.log("QRZ-2 nonsub DATA SET");
                                                        this.qrzSub = false;
                                                        this.qrzNotFound = false;
                                                        this.qrzNonSub = res.QRZDatabase.Callsign;
                                                        //this.newlog.callsign = this.qrzNonSub.call;
                                                        this.newlog.city = this.qrzNonSub.addr2;
                                                        this.newlog.country = this.qrzNonSub.country;
                                                        this.newlog.firstName = this.qrzNonSub.fname;
                                                        this.newlog.fullName = this.qrzNonSub.fname + " " + this.qrzNonSub.name;
                                                        this.newlog.lastName = this.qrzNonSub.name;
                                                        this.newlog.state = this.qrzNonSub.state;
                                                        this.newlog.lookupSource = "QRZ";
                                                    }
                                                    else {
                                                        console.log("QRZ-2 Subscriber DATA SET");
                                                        this.qrzNotFound = false;
                                                        this.qrzSubscriber = res.QRZDatabase.Callsign;
                                                        //this.newlog.callsign = this.qrzSubscriber.call;
                                                        this.newlog.city = this.qrzSubscriber.addr2;
                                                        this.newlog.country = this.qrzSubscriber.country;
                                                        this.newlog.firstName = this.qrzSubscriber.fname;
                                                        this.newlog.fullName = this.qrzSubscriber.fname + " " + this.qrzSubscriber.name;
                                                        this.newlog.lastName = this.qrzSubscriber.name;
                                                        this.newlog.state = this.qrzSubscriber.state;
                                                        this.newlog.address = this.qrzSubscriber.addr1;
                                                        this.newlog.dxcc = this.qrzSubscriber.dxcc;
                                                        this.newlog.cqz = this.qrzSubscriber.cqzone;
                                                        this.newlog.ituz = this.qrzSubscriber.ituzone;
                                                        this.newlog.grid = this.qrzSubscriber.grid;
                                                        this.newlog.altCallsign = this.qrzSubscriber.aliases;
                                                        this.newlog.class = this.qrzSubscriber.class;
                                                        this.newlog.lat = this.qrzSubscriber.lat;
                                                        this.newlog.lon = this.qrzSubscriber.lon;
                                                        this.newlog.email = this.qrzSubscriber.email;
                                                        this.newlog.image = this.qrzSubscriber.image;
                                                        this.newlog.bio = this.qrzSubscriber.bio;
                                                        this.newlog.lookupSource = "QRZ";
                                                    }
                                                }
                                            }
                                        });
                                    }
                                }
                            },
                            error: (err) => {
                                this.qrzNotFound = true;
                                console.error(err);
                            }
                        });
                    }
                    else {
                        if (res.QRZDatabase.Session.SubExp == "non-subscriber") {
                            console.log("QRZ-1 nonsub DATA SET");
                            this.qrzSub = false;
                            this.qrzNotFound = false;
                            this.qrzNonSub = res.QRZDatabase.Callsign;
                            //this.newlog.callsign = this.qrzNonSub.call;
                            this.newlog.city = this.qrzNonSub.addr2;
                            this.newlog.country = this.qrzNonSub.country;
                            this.newlog.firstName = this.qrzNonSub.fname;
                            this.newlog.fullName = this.qrzNonSub.fname + " " + this.qrzNonSub.name;
                            this.newlog.lastName = this.qrzNonSub.name;
                            this.newlog.state = this.qrzNonSub.state;
                            this.newlog.lookupSource = "QRZ";
                        }
                        else {
                            console.log("QRZ-1 Subscriber DATA SET");
                            this.qrzNotFound = false;
                            this.qrzSubscriber = res.QRZDatabase.Callsign;
                            //this.newlog.callsign = this.qrzSubscriber.call;
                            this.newlog.city = this.qrzSubscriber.addr2;
                            this.newlog.country = this.qrzSubscriber.country;
                            this.newlog.firstName = this.qrzSubscriber.fname;
                            this.newlog.fullName = this.qrzSubscriber.fname + " " + this.qrzSubscriber.name;
                            this.newlog.lastName = this.qrzSubscriber.name;
                            this.newlog.state = this.qrzSubscriber.state;
                            this.newlog.address = this.qrzSubscriber.addr1;
                            this.newlog.dxcc = this.qrzSubscriber.dxcc;
                            this.newlog.cqz = this.qrzSubscriber.cqzone;
                            this.newlog.ituz = this.qrzSubscriber.ituzone;
                            this.newlog.grid = this.qrzSubscriber.grid;
                            this.newlog.altCallsign = this.qrzSubscriber.aliases;
                            this.newlog.class = this.qrzSubscriber.class;
                            this.newlog.lat = this.qrzSubscriber.lat;
                            this.newlog.lon = this.qrzSubscriber.lon;
                            this.newlog.email = this.qrzSubscriber.email;
                            this.newlog.image = this.qrzSubscriber.image;
                            this.newlog.bio = this.qrzSubscriber.bio;
                            this.newlog.lookupSource = "QRZ";
                        }
                    }
                }
            }
        });
        return this;
    }
}
