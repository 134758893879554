<app-menu></app-menu>
<app-time-out-modal></app-time-out-modal>

<ejs-toast id="toast" #toastMessageBox [position]='toastMessagePosition'>
    <ng-template #title>
        <div>{{toastMessageTitle}}</div>
    </ng-template>
    <ng-template #content>
        <div>{{toastMessageContent}}</div>
    </ng-template>
</ejs-toast>

<h2 style="margin-left: 10px;">Group Invitations</h2>

<div class="mb-3" style="text-align: center;">
    <b>Show Completed: </b>
    <ejs-checkbox [(checked)]="showAll" (checkedChange)="onCheckedChange()"></ejs-checkbox><br>
    <b>Show Expired: </b>
    <ejs-checkbox [(checked)]="showExpired" (checkedChange)="onCheckedChange()"></ejs-checkbox><br>
</div>
<div class="row align-content-center align-items-center">
    <div class="col-md-12">
        <div class="alert" [ngClass]="setAlertColor(x.isAccepted, x.dateExpires)" style="width: 90%; margin: 0 auto; margin-bottom:15px;" *ngFor="let x of invitations">
            <div class="row">
                <div class="col-md-2">
                    <b>From:</b> {{x.inviteFromName}}
                </div>
                <div class="col-md-2">
                    <b>Group:</b> {{x.groupName}}
                </div>
                <div class="col-md-2">
                    <b>Expires: </b> {{x.dateExpires | date: 'shortDate'}}
                </div>
                <div class="col-md-2">
                    <b>Status: </b>{{setStatus(x.isAccepted, x.dateExpires)}}
                </div>
                <div *ngIf="!x.isAccepted && parseDateTime(x.dateExpires) > parseDateTime(dateNow)" class="col-md-2">
                    <a class="btn btn-success btn-sm invite-button" role="button" (click)="actionInvite('accept', x)" >Accept</a>
                    <a class="btn btn-danger btn-sm invite-button" role="button" (click)="actionInvite('decline', x)" >Decline</a>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <b>Message: </b>{{x.invitationMessage}}
                </div>
            </div>
        </div>
    </div>
</div>
