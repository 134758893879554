<app-menu #appmenu></app-menu>

<app-sub-menu (onSessionTimerClick)="openSessionTimeoutModal()" (onSaveClick)="save()" [user]="user"
  pageTitle="Settings" [searching]="true"></app-sub-menu>
<app-time-out-modal></app-time-out-modal>

<app-serial-interface #rigcontrol *ngIf="currentSelectedProfile && currentSelectedProfile.rigControlEnabled"
  [profileId]="currentSelectedProfile.id"></app-serial-interface>

<ejs-toast id="toast" #toastMessageBox [position]='toastMessagePosition'>
    <ng-template #title>
        <div>{{toastMessageTitle}}</div>
    </ng-template>
    <ng-template #content>
        <div>{{toastMessageContent}}</div>
    </ng-template>
</ejs-toast>

<!-- Modal UPLOAD ADI FILE -->
<div class="modal fade" id="SetUploadModal" tabindex="-1" aria-labelledby="SetUploadModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="SetUploadModalLabel">Import ADIF File</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <input type="file" style="display: none;" accept=".adi" #importCSV (change)="fileSelection($event)">
        <button class="btn btn-sm btn-primary" style="margin: 10px;" [disabled]="currentlyUploading"
          (click)="importCSV.click()">Browse</button>
        <input [value]="fileName">
        <button class="btn btn-sm btn-success" style="margin: 10px;" [disabled]="!currentFile || currentlyUploading"
          (click)="uploadFileAdi()">Upload</button>
        <button *ngIf="currentlyUploading && !uploadSuccess" class="btn btn-sm btn-danger"
          style="margin: 10px; margin-left: 5px;" (click)="cancelUpload()">Cancel</button>
        <div class="progress">
          <div class="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"
            [style.width]="progress + '%'">
            {{progress}}%
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div style="display: block; width: 100%;">
          <div style="float:left; margin: auto;">
            <div *ngIf="uploadSuccess" style="display: block;">
              <span style="font-weight: bold;">Upload Details:</span><br>
              <span>QSO Count: {{newLogRowCount}}</span><br>
              <span>Duplicates: {{newLogDuplicates}}</span>
            </div>
          </div>
          <div style="margin: auto"></div>
          <div style="float: right; margin: auto;">
            <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">Close</button>
            <span style="padding-left: 20%;"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal CONFIGURE GROUP ADMIN -->
<div *ngIf="user" class="modal fade" id="group-admin-modal" tabindex="-1" aria-labelledby="group-admin-modal-label" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="group-admin-modal-label">Group Admin Configuration</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>Share this logbook with other users!</p>
        <p style="color:red;">Invite other operators to become contributors of this logbook as a group or club.</p>
        <span><b>Enable:  </b></span>
        <ejs-switch [(checked)]="groupAdminEnabled"></ejs-switch><br>
        <div *ngIf="groupAdminEnabled">
          <label><b>Enter a name for your group:</b> "Amateur Radio Club W1AW"</label>
          <ejs-textbox placeholder="Group Name 	&ldquo;Amateur Radio Club W1AW&rdquo;" [(value)]="groupName"></ejs-textbox>
        </div>
      </div>
      <div class="modal-footer">
        <span *ngIf="user.isGroupAdmin == true && groupAdminEnabled == false" style="margin-left:10px; color: red;">CAUTION!! This Action Will Remove ALL references from the system.</span>
        <div style="display: block; width: 100%;">
          <div style="float: left; margin: auto;">
            <button style="margin: 10px;" type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cancel</button>
          </div>
          <div style="margin: auto;"></div>
          <div style="float: right; margin: auto;">
            <button style="margin: 10px;" class="btn btn-sm btn-success" data-bs-dismiss="modal" (click)="changeUserGroupAdmin()">Save</button>
          </div>
          <span style="padding-left: 20%;"></span>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal ADD MEMBERS -->
<div *ngIf="user" class="modal fade" id="add-members-modal" tabindex="-1" aria-labelledby="add-members-modal-label" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="add-members-modal-label">Add Members to the Group.</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <span><b>Callsign:</b></span><br>
        <ejs-textbox [(value)]="inviteCallsign" placeholder="Callsign to invite"></ejs-textbox>
        <span><b>Send Email Notification?</b></span>
        <ejs-checkbox style="margin-left:10px;" [(ngModel)]="inviteSendEmail"></ejs-checkbox><br>
        <span><b>Personal Message:</b></span><br>
        <textarea style="width: 100%; line-height: 1;" [(ngModel)]="inviteMessage"></textarea>
      </div>
      <div class="modal-footer">
        <div style="display: block; width: 100%;">
          <div style="float: left; margin: auto;">
            <button style="margin: 10px;" type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cancel</button>
          </div>
          <div style="margin: auto;"></div>
          <div style="float: right; margin: auto;">
            <button style="margin: 10px;" class="btn btn-sm btn-success" data-bs-dismiss="modal" (click)="sendInvite()">Send</button>
          </div>
          <span style="padding-left: 20%;"></span>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal VIEW INVITATION STATUS -->
<div *ngIf="user" class="modal fade" id="view-invitation-status-modal" tabindex="-1" aria-labelledby="view-invitation-status-modal-label" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="view-invitation-status-modal-label">Group Member Invitation Status</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" style="max-height: 600px; overflow-y: scroll;">
        <h5>Active Invitation Requests</h5>
        <table>
          <thead>
            <tr>
              <th>Member Name</th>
              <th>Email</th>
              <th>Status</th>
              <th>Expires</th>
              <th>Accepted</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let x of groupInvitations">
              <td>{{x.inviteToName}}</td>
              <td><ejs-checkbox [disabled]="true" [(checked)]="x.emailSent"></ejs-checkbox></td>
              <td>{{setStatus(x.isAccepted, x.dateExpires)}}</td>
              <td><span *ngIf="!x.isAccepted">{{x.dateExpires | date:'shortDate'}}</span></td>
              <td><span *ngIf="x.isAccepted">{{x.dateAccepted | date:'shortDate'}}</span></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <div style="display: block; width: 100%;">
          <div style="float: left; margin: auto;">
            <button style="margin: 10px;" type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cancel</button>
          </div>
          <div style="margin: auto;"></div>
          <div style="float: right; margin: auto;">
            <!-- button here -->
          </div>
          <span style="padding-left: 20%;"></span>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal New Profile -->
<div class="modal fade" id="NewProfileModal" tabindex="-1" aria-labelledby="NewProfileModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="NewProfileModalLabel">Create A New Profile</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <table>
          <tr>
            <td style="font-weight: bold;">Profile Name:</td>
            <td><ejs-textbox [(value)]="newProfile.profileName"></ejs-textbox></td>
          </tr>
          <tr>
            <td style="font-weight: bold;">Calls to show in Table:</td>
            <td><ejs-dropdownlist [dataSource]="numberOfCallsToShow"
                [(value)]="newProfile.priviousCallsToShow"></ejs-dropdownlist></td>
          </tr>
          <tr>
            <td style="font-weight: bold;">Log Mode:</td>
            <td><ejs-dropdownlist [dataSource]="logModeBaseOptions" [(value)]="newLogModeOption"></ejs-dropdownlist>
            </td>
          </tr>
          <tr *ngIf="newLogModeOption == 'CONTEST'">
            <td style="font-weight: bold;">Contest:</td>
            <td><ejs-dropdownlist [fields]="contestfields" [dataSource]="contests" [(value)]="newContestOption"></ejs-dropdownlist>
            </td>
          </tr>
          <tr>
            <td style="font-weight: bold;">Use 24 hour:</td>
            <td><ejs-switch [(checked)]="newUse24Hour"></ejs-switch></td>
          </tr>
          <tr>
            <td style="font-weight: bold;">Lookup previous logs:</td>
            <td><ejs-checkbox [(ngModel)]="newAutomaticLookupPreviousLogs"></ejs-checkbox></td>
          </tr>
          <tr>
            <td style="font-weight: bold;">Show NET Column:</td>
            <td><ejs-checkbox [(ngModel)]="newProfile.showNetColumn"></ejs-checkbox></td>
          </tr>
          <tr>
            <td style="font-weight: bold;">Show Address Column:</td>
            <td><ejs-checkbox [(ngModel)]="newProfile.showAddressColumn"></ejs-checkbox></td>
          </tr>
          <tr>
            <td style="font-weight: bold;">Show DX Spots</td>
            <td><ejs-checkbox [(ngModel)]="newProfile.showDxSpot"></ejs-checkbox></td>
          </tr>
        </table>
      </div>
      <div class="modal-footer">
        <button class="btn btn-sm btn-success" (click)="createNewUserProfile()" data-bs-dismiss="modal">Create</button>
        <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">Close</button>
        <span style="padding-left: 20%;"></span>
      </div>
    </div>
  </div>
</div>

<!-- Modal Edit Profile -->
<div class="modal fade" id="EditProfileModal" tabindex="-1" aria-labelledby="EditProfileModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="EditProfileModalLabel">Edit Profile:</h5>
        <ejs-dropdownlist style="display: inline-block; margin-left: 10px; margin-right: 10px;"
          [dataSource]="availableProfiles" [fields]="fields" [(value)]="selectProfileId"
          (change)="changeSelectedProfile($event)"></ejs-dropdownlist>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="resetModalOptions()"></button>
      </div>
      <div class="modal-body">
        <span *ngIf="!editableProfile" style="color: red;">Note: The Default Profile Cannot Be Removed.</span>
        <table *ngIf="currentSelectedProfile">
          <tr>
            <td style="font-weight: bold;">Profile Name:</td>
            <td><ejs-textbox [(value)]="currentSelectedProfile.profileName" [enabled]="editableProfile"></ejs-textbox>
            </td>
          </tr>
          <tr>
            <td style="font-weight: bold;">Calls to show in Table:</td>
            <td><ejs-dropdownlist [dataSource]="numberOfCallsToShow"
                [(value)]="currentSelectedProfile.priviousCallsToShow"></ejs-dropdownlist></td>
          </tr>
          <tr>
            <td style="font-weight: bold;">Log Mode:</td>
            <td><ejs-dropdownlist [dataSource]="logModeBaseOptions" [(value)]="logModeOptions.logMode"></ejs-dropdownlist>
            </td>
          </tr>
          <tr *ngIf="logModeOptions.logMode == 'CONTEST'">
            <td style="font-weight: bold;">Contest:</td>
            <td><ejs-dropdownlist [fields]="contestfields" [dataSource]="contests" [(value)]="logModeOptions.contest"></ejs-dropdownlist>
            </td>
          </tr>
          <tr>
            <td style="font-weight: bold;">Use 24 hour:</td>
            <td><ejs-switch [(checked)]="use24Hour"></ejs-switch></td>
          </tr>
          <tr>
            <td style="font-weight: bold;">Lookup previous logs:</td>
            <td><ejs-checkbox [(ngModel)]="automaticLookupPreviousLogs"></ejs-checkbox></td>
          </tr>
          <tr>
            <td style="font-weight: bold;">Show Net Column:</td>
            <td><ejs-checkbox [(ngModel)]="currentSelectedProfile.showNetColumn"></ejs-checkbox></td>
          </tr>
          <tr>
            <td style="font-weight: bold;">Show Address Column:</td>
            <td><ejs-checkbox [(ngModel)]="currentSelectedProfile.showAddressColumn"></ejs-checkbox></td>
          </tr>
          <tr>
            <td style="font-weight: bold;">Show DX Spotting:</td>
            <td><ejs-checkbox [(ngModel)]="currentSelectedProfile.showDxSpot"></ejs-checkbox></td>
          </tr>
        </table>
      </div>
      <div class="modal-footer">
        <div style="display: block; width: 100%;">
          <div style="float: left; margin: auto;">
            <button style="margin: 10px;" type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"
              (click)="resetModalOptions()">Close</button>
          </div>
          <div style="margin: auto;"></div>
          <div style="float: right; margin: auto;">
            <button style="margin: 10px;" class="btn btn-sm btn-success" (click)="editSaveSelectedProfile()"
              data-bs-dismiss="modal">Save</button>
            <button style="margin: 10px; margin-left: 0px;" class="btn btn-sm btn-danger"
              (click)="deleteProfileFromModal()" [disabled]="!editableProfile">{{modalDeleteOrCancel}}</button>
            <button *ngIf="areYouSureModalDelete" (click)="deleteFromModalVerified()" class="btn btn-danger btn-sm"
              data-bs-dismiss="modal">ARE YOU SURE?</button>

          </div>
          <span style="padding-left: 20%;"></span>

        </div>

      </div>
    </div>
  </div>
</div>
<table>
  <tr>
    <td>
      <!-- <div style="margin-top:10px; margin-bottom: 10px;">
        <span style="font-weight: bold; margin-right: 10px; display: inline-block;">Message Center: </span>
        <input *ngIf="error" style="color:red;" readonly [value]="message" [size]="myInput.value.length" #myInput>
        <input *ngIf="!error" style="color:green;" readonly [value]="message" [size]="myInput.value.length" #myInput>
      </div> -->
    </td>
  </tr>
  <tr>
    <td class="settings-list-table-data">
      <span style="margin-left: 10px; font-weight: bold;">Available Profiles:</span>
      <ejs-dropdownlist style="display: inline-block; margin-left: 10px; margin-right: 10px;"
        [dataSource]="availableProfiles" [fields]="fields" [(value)]="selectProfileId"
        (change)="changeSelectedProfile($event)"></ejs-dropdownlist><br>

      <button style="margin: 10px; margin-right: 0px;" class="btn btn-sm btn-primary" data-bs-toggle="modal"
        data-bs-target="#NewProfileModal">New Profile</button>
      <button style="margin: 10px;" class="btn btn-sm btn-primary" data-bs-toggle="modal"
        data-bs-target="#EditProfileModal" (click)="setOriginalPname()">Edit</button><br>
    </td>
  </tr>
  <tr *ngIf="user && !user.isGroupAdmin">
    <td style="padding: 5px;"></td>
  </tr>
  <tr *ngIf="user && !user.isGroupAdmin">
    <td class="settings-list-table-data">
      <span style="margin-left: 10px; font-weight: bold;">Operate As Group:</span><br>
      <span style="margin-left: 10px;">Status: {{user.operateAsGroup ? "Enabled" : "Disabled"}}</span><br>
      <button style="margin:10px; margin-right: 0;" class="btn btn-sm btn-primary" (click)="selectGroupBtn()">Select A Group</button>
      <button style="margin:10px;" *ngIf="user.operateAsGroup" class="btn btn-sm btn-danger" (click)="disableSelectedGroup()">Disable</button>
      <ejs-dropdownlist *ngIf="showGroupSelect" style="display: inline-block; margin-left: 10px; margin-right: 10px; width: 90%;"
          [dataSource]="myGroups" [fields]="groupfields" [(value)]="selectedGroupId"
          (change)="changeSelectedGroup($event)"></ejs-dropdownlist>
    </td>
  </tr>
  <tr>
    <td style="padding: 5px;"></td>
  </tr>
  <tr>
    <td style="border: 2px solid black;">
      <span style="margin-left: 10px; margin-right: 10px; font-weight: bold;">Enable QRZ lookup:</span><ejs-switch
        [(checked)]="qrzEnabled" (change)="changeUserQRZsettings()"></ejs-switch><br>
      <div *ngIf="qrzEnabled">
        <form>
          <table>
            <tr>
              <td>
                <span style="font-weight: bold;">QRZ Username:</span>
              </td>
              <td>
                <ejs-textbox autocomplete="username" [(value)]="qrzUser"></ejs-textbox>
              </td>
            </tr>
            <tr>
              <td>
                <span style="font-weight: bold;">QRZ Password:</span>
              </td>
              <td>
                <ejs-textbox type="password" autocomplete="current-password" [(value)]="qrzPwd"></ejs-textbox>
              </td>
              <td>
                <button style="margin-left: 10px;" class="btn btn-sm btn-primary"
                  (click)="changeUserQRZsettings()">Save</button>
              </td>
            </tr>
          </table>
        </form>
      </div>
    </td>
  </tr>
  <tr>
    <td style="padding: 5px;"></td>
  </tr>
  <tr>
    <td style="border: 2px solid black;" *ngIf="currentSelectedProfile">
      <span style="margin-left: 10px; margin-right: 10px; font-weight: bold;">Enable Rig Control:</span><ejs-switch
        [(checked)]="currentSelectedProfile.rigControlEnabled"
        (change)="editSaveSelectedProfile(true)"></ejs-switch><br>
      <button *ngIf="currentSelectedProfile.rigControlEnabled" style="margin: 10px;"
        class="btn btn-sm btn-primary" (click)="rigControl?.openSerialSettingsModal()">Settings</button>
    </td>
  </tr>
  <tr>
    <td style="padding: 5px;"></td>
  </tr>
  <tr>
    <td style="border: 2px solid black;">
      <span style="font-weight: bold; margin-left: 10px;">Import / Export ADIF</span><br>
      <button style="margin: 10px; margin-right: 5px;" class="btn btn-sm btn-primary" data-bs-toggle="modal"
        data-bs-target="#SetUploadModal">Import ADIF File</button>
      <span *ngIf="currentlyUploading" style="color: red;">Uploading! Please Wait...</span><br>
      <span style="font-weight: bold; margin-left: 10px;">Export Complete Logbook as ADIF</span><br>
      <button style="margin: 10px; margin-right: 5px;" class="btn btn-sm btn-primary" (click)="exportFileAdi()">Export</button>
      <span *ngIf="downloadingExport" style="color: red;">Downloading! Please Wait...</span>

    </td>
  </tr>
  <tr>
    <td style="padding: 5px;"></td>
  </tr>
  <tr *ngIf="user">
    <td style="border: 2px solid black;">
      <span class="margin-left-10" style="font-weight: bold;">Group Admin Configuration</span><br>
      <span class="margin-left-10" style="color:red">(For Club Use Only)</span><br>
      <span class="margin-left-10">Share this logbook with other users.</span><br>
      <span class="margin-left-10"><b>Status: </b>{{user.isGroupAdmin ? "Sharing" : "Disabled"}}</span><br>
      <span *ngIf="user.isGroupAdmin" class="margin-left-10"><b>Group Name: </b>{{user.groupName}}</span><br>
      <button style="margin: 10px; margin-right: 5px;" data-bs-toggle="modal" data-bs-target="#group-admin-modal" class="btn btn-sm btn-primary">Configure</button>
      <button *ngIf="user.isGroupAdmin" style="margin-right: 5px;" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#view-invitation-status-modal">Invitation Status</button>
      <button *ngIf="user.isGroupAdmin" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#add-members-modal">Add Members</button>
    </td>
  </tr>
  <tr>
    <td style="padding: 5px;"></td>
  </tr>
  <tr *ngIf="user">
    <td style="border: 2px solid black;">
      <span class="margin-left-10" style="font-weight: bold;">Backups</span><br>
      <span class="margin-left-10">Download a complete backup file (includes user configuration)</span><br>
      <button style="margin: 10px; margin-right: 5px;" class="btn btn-sm btn-primary" (click)="exportBackup()">Download Backup</button>
      <span *ngIf="downloadingBackup" style="color: red;">Downloading! Please Wait...</span><br>
      <span class="margin-left-10" style="font-weight: bold;">Restore From Backup</span><br>
      <span class="margin-left-10" style="color:red">CAUTION!!! Restoring will overwrite current data.</span><br>
      <span class="margin-left-10" style="color:red">Select which sections should be restored below:</span><br>
      <span class="margin-left-10" style="color:red">(If nothing is selected, no action will be taken.)</span><br>

      <table style="text-align: right;">
        <tr>
          <th>Logbook:</th>
          <td><ejs-checkbox [(ngModel)]="restoreHamlogs"></ejs-checkbox></td>
          <th>User Profiles:</th>
          <td><ejs-checkbox [(ngModel)]="restoreUserSettings"></ejs-checkbox></td>
        </tr>
        <tr>
          <th>Net Presets:</th>
          <td><ejs-checkbox [(ngModel)]="restoreNetPresets"></ejs-checkbox></td>
          <th>Groups:</th>
          <td><ejs-checkbox [(ngModel)]="restoreGroups"></ejs-checkbox></td>
        </tr>
      </table>
      <input type="file" style="display: none;" accept=".json" #importJson (change)="fileSelection($event)">
      <button class="btn btn-sm btn-primary" style="margin: 10px;" [disabled]="restoreInProgress"
        (click)="importJson.click()">Browse</button>
      <input [value]="fileName">
      <button class="btn btn-sm btn-success" style="margin: 10px;" [disabled]="!currentFile || restoreInProgress"
        (click)="uploadFileJson()">Restore From File</button><br>
      <span *ngIf="restoreInProgress" style="color: red;">Restore In Progress! Please Wait...</span><br>

    </td>
  </tr>
</table>