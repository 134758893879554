import { Component } from '@angular/core';
import { SectionAbbriviations } from '../../sectionabblist';

@Component({
  selector: 'app-field-day',
  templateUrl: './field-day.component.html',
  styleUrl: './field-day.component.css'
})
export class FieldDayComponent {



sections: Object = SectionAbbriviations;

constructor() {}



  testfunc() {
    SectionAbbriviations[1]
  }


  hamlogUpdateRequest(){

  }

}
