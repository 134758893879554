
//based on string length...
export function insertDecimal(cf) {
    if (cf.length == 7) {
        return cf.slice(0, 2) + "." + cf.slice(2);
    }
    else if (cf.length == 6) {
        return cf.slice(0, 1) + "." + cf.slice(1);
    }
    else if (cf.length == 5) {
        return cf.slice(0, 0) + "0." + cf.slice(0);
    }
    else {
        return cf;
    }
}

export function parseToHz(frequency: string): string {
    let twoPart = frequency.split(".");
    let joined = twoPart.join('');
    let out = "";
    let outLength = 0;
    if(twoPart[0].length == 0){
        outLength = 6;
    }
    else if(twoPart[0].length == 1){
        outLength = 7;
    }
    else if(twoPart[0].length == 2){
        outLength = 8;
    }
    else {
        return joined;
    }
    if(joined.length < outLength){
        let add = outLength - joined.length;
        let mod = joined;
        for(let i = 0; i < add; i++){
            mod += "0";
        }
        out = mod;
    }
    else if(joined.length > outLength){
        let sub = outLength - joined.length;
        let mod = joined;
        out = mod.slice(0, sub);
    }
    else if(joined.length == outLength){
        out = joined;
    }
    else {
        return joined;
    }
    return out;
}