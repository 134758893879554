import { DefaultGuid } from "../settings/globalVars";
export class Netlog{
    id: string = DefaultGuid;
    hamLogId: string = DefaultGuid;
    netRtId: string = DefaultGuid;
    netName: string = "";
    offTime: string | Date | null = null;
    checkedOut: boolean = false;
    isRoundTable: boolean = false;
    oneAndDone: boolean = false;
    isNet: boolean = false;
}