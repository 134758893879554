<div id="component">
    <app-menu></app-menu>
    <header>
        
        <span id="pageTitle">{{pageTitle}}</span>
    </header>
    <main>
        <div class="table-responsive">
            <table class="table table-striped table-sm" *ngIf="user">
                <tbody>
                    <tr>
                        <td>Id</td>
                        <td><input [(ngModel)]="user.id" readonly disabled="true"></td>
                    </tr>
                    <tr>
                        <td>Record Count</td>
                        <td><input [(ngModel)]="user.recordCount" readonly disabled="true"></td>
                    </tr>
                    <tr>
                        <td>Admin</td>
                        <td><input [(ngModel)]="user.isAdmin" readonly disabled="true"></td>
                    </tr>
                    <tr>
                        <td>First Name</td>
                        <td><input [(ngModel)]="user.firstName" readonly disabled="true"></td>
                    </tr>
                    <tr>
                        <td>Callsign</td>
                        <td><input [(ngModel)]="user.callsign" readonly disabled="true"></td>
                    </tr>
                    <tr>
                        <td>Last Name</td>
                        <td><input [(ngModel)]="user.firstName" readonly disabled="true"></td>
                    </tr>
                    <tr>
                        <td>Username</td>
                        <td><input [(ngModel)]="user.userName" readonly disabled="true"></td>
                    </tr>
                    <tr>
                        <td>Password</td>
                        <td><input [(ngModel)]="user.password" type="password" readonly disabled="true"></td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td><input [(ngModel)]="user.email" readonly disabled="true"></td>
                    </tr>
                    <tr>
                        <td>Address</td>
                        <td><input [(ngModel)]="user.address" readonly disabled="true"></td>
                    </tr>
                    <tr>
                        <td>City</td>
                        <td><input [(ngModel)]="user.city" readonly disabled="true"></td>
                    </tr>
                    <tr>
                        <td>State</td>
                        <td><input [(ngModel)]="user.state" readonly disabled="true"></td>
                    </tr>
                    <tr>
                        <td>Zip</td>
                        <td><input [(ngModel)]="user.zip" readonly disabled="true"></td>
                    </tr>
                    <tr>
                        <td>QRZ User Name</td>
                        <td><input [(ngModel)]="user.qrzUserName" readonly disabled="true"></td>
                    </tr>
                    <tr>
                        <td>QRZ Password</td>
                        <td><input [(ngModel)]="user.qrzPassword" readonly disabled="true"></td>
                    </tr>
                    <tr>
                        <td>Date Created</td>
                        <td><input [(ngModel)]="user.dateCreated" readonly disabled="true"></td>
                    </tr>
                    <tr>
                        <td>Date Modified</td>
                        <td><input [(ngModel)]="user.dateLastModified" readonly disabled="true"></td>
                    </tr>
                    <tr>
                        <td>Date Last Login</td>
                        <td><input [(ngModel)]="user.dateLastLogin" readonly disabled="true"></td>
                    </tr>
                </tbody>
            </table>

        </div>
        <a style="margin-left: 10px; margin-right: 10px;" routerLink="/user/list" class="btn btn-primary btn-sm">Back</a>
        <button (click)="delete()" class="btn btn-danger btn-sm">Delete</button>
        <button style="margin-left: 10px;" *ngIf="areYouSure" (click)="deleteVerified()" class="btn btn-danger btn-sm">ARE YOU SURE?</button>
        
    </main>
</div>