export class LoginCredentials {
    userName: string = "";
    password: string = "";
    token: string = "";
    additionalData: string = "";
    instanceId: string = "";
}
export class RenewLoginCredentials {
    userId: string = "";
    instanceId: string = "";
}
export class RestoreUserData {
    userId: string = "";
    instanceId: string = "";
    exp: string | Date | null = null;
}