import { DatePipe } from '@angular/common';
import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { User } from 'src/app/user/user.class';
import { UserService } from 'src/app/user/services/user.service';
import { RenewLoginCredentials } from 'src/app/user/logincredentials.class';
import { LoggerService } from 'src/app/user/services/logger.service';
import { clearInterval, setInterval } from 'worker-timers';


@Component({
  selector: 'app-time-out-modal',
  templateUrl: './time-out-modal.component.html',
  styleUrl: './time-out-modal.component.css'
})
export class TimeOutModalComponent {

  @ViewChild('TimeOutModal') timeoutmodal?: ElementRef;
  intervalId: any;
  pipe = new DatePipe('en-US');
  public now: number = 0;
  public exp: number = 0;
  public minutesR: number = 0;
  public secondsR: number = 0;
  public hoursR: number = 0;
  public outExp: number = 0;
  public diff: number = 0;
  showcount: number = 0;
  private user: User = {} as any;
  
  constructor(
    private router: Router,
    private userSvc: UserService,
    private _sys: LoggerService,
  ){}

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: BeforeUnloadEvent) {
    this.userSvc.killSession();
  }

  
  getDecodedAccessToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch(Error) {
      return null;
    }
  }

  renewUser() {
    var ssUserId = sessionStorage.getItem('userId');
    if(sessionStorage.getItem('isLoggedIn') == 'true' && ssUserId !== null){
      var data = new RenewLoginCredentials();
      data.userId = ssUserId;
      data.instanceId = this._sys.instanceId;
      this.userSvc.renew(data).subscribe({
        next: (res) => {
          this.user = res;
          var keyData = this.getDecodedAccessToken(this.user.xApiKey.toString());
          sessionStorage.setItem('TokenExp', keyData.exp);
          sessionStorage.setItem('XApiKey', this.user.xApiKey.toString());
          sessionStorage.setItem('isLoggedIn', 'true');
          sessionStorage.setItem('isAdmin', this.user.isAdmin.toString());

          this.refresh();
        },
        error: (err) => {
          console.error("Renewal Error", err);
          ($('#TimeOutModal') as any).modal('hide');
          this.logoutNow();
        }
      });
    }
  }

  setClockTime() {
    this.intervalId = setInterval(() => {
      this.now = new Date().getTime();
      this.outExp = new Date(this.exp).getTime();
      this.diff = this.outExp - this.now;
      this.minutesR = Math.floor(((this.diff/(1000*60))%60));
      this.secondsR = Math.floor(((this.diff/1000)%60));
      this.hoursR = Math.floor(((this.diff/(1000*60*60))%24));
      if(!$('#TimeOutModal').is('visible') && this.minutesR <= 15 && this.hoursR < 1 && this.showcount < 1){
        this.showcount ++;
        ($('#TimeOutModal') as any).modal('show');
      }
      if(this.hoursR <= 0 && this.minutesR <= 0 && this.secondsR <= 0){
        clearInterval(this.intervalId);
        this.logoutNow();

      }
    }, 1000);
  }
  logoutNow() {
    ($('#TimeOutModal') as any).modal('hide');
    this.userSvc.logout();
  }
  refresh() {
    clearInterval(this.intervalId);
    let newExp = sessionStorage.getItem("TokenExp") + "000";
    this.exp = newExp == null ? 0 : +newExp

    this.setClockTime();
  }
  ngOnDestroy() {
    clearInterval(this.intervalId);
  }
  
  ngOnInit() {
    this.refresh();
  }
}
