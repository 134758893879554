<button style="margin:10px;" (click)="pause()" class="btn btn-sm btn-warning" *ngIf="!paused">Pause Spotting</button>
<button style="margin:10px;" (click)="unPause()" class="btn btn-sm btn-success" *ngIf="paused">Resume Spotting</button>

<ejs-grid #grid [dataSource]="spotsDataManager" [height]="tableHeight" [allowResizing]="true" [autoFit]="true"
    [allowFiltering]="true" [filterSettings]="filterSettings" [allowReordering]="true" [allowPaging]="true" [pageSettings]="pageSettings" [enablePersistence]="true" (load)="tableOnLoad()">
    <e-columns>
        <e-column field="id" headerText="More" textAlign="Center" minWidth="40" width="40" maxWidth="40"
            [isPrimaryKey]="true" [allowReordering]="false">
            <ng-template #template let-data>
                <div class="e-section-control">
                    <button class="btn btn-primary btn-sm btn-dxspots" style="text-align: center;"
                        routerLink="/spots/detail/{{data.nr}}">+</button>
                </div>
            </ng-template>
        </e-column>
        <e-column field='call' headerText='Call' textAlign="Center" minWidth="60" width="60" maxWidth="200"></e-column>
        <e-column field='freq' headerText='Freq' textAlign="Center" minWidth="75" width="75" maxWidth="200"
            format="N2"></e-column>
        <e-column field='dxcall' headerText='DX Call' textAlign="Center" minWidth="75" width="75"
            maxWidth="200"></e-column>
        <e-column field='time' headerText='Date' textAlign="Center" minWidth="60" width="60" maxWidth="200" type="date"
            [format]="dateFormatOptions" [filterBarTemplate]="templateOptionsDatePicker"></e-column>
        <e-column field='time' headerText='Time' textAlign="Center" minWidth="60" width="60" maxWidth="200" type="date"
            [format]="timeFormatOptions"></e-column>
        <e-column field='band' headerText='Band' textAlign="Center" minWidth="50" width="100" maxWidth="150"
            [filterBarTemplate]="templateOptionsDropDown"></e-column>
        <e-column field='dxName' headerText='DX Name' textAlign="Center" minWidth="75" width="75"
            maxWidth="200"></e-column>
        <e-column field='dxCont' headerText='DX Cont' textAlign="Center" minWidth="75" width="75"
            maxWidth="200"></e-column>
        <e-column field="dxCont" headerText="" textAlign="Center" minWidth="40" width="40" maxWidth="40">
            <ng-template #template let-data>
                <img *ngIf="hasFlagInData(data.dxCont)" alt="{{data.dxCont}}"
                    src="assets/images/country-flag-icons/3x2/{{data.dxCont}}.svg" />
            </ng-template>
        </e-column>
        <e-column field='comment' headerText='Comments' textAlign="Center" minWidth="60" width="250"
            maxWidth="300"></e-column>
    </e-columns>
</ejs-grid>