<footer>
    <div class="wrapper">
        
    </div>
    <div id="footer">
        <span id="footer_text">
            <a href="https://zjtlabs.com" target="_blank">
                Powered By &reg;ZJTLABS LLC.
            </a>
        </span>
        <div class="cat-wrapper"> <canvas id="cat" width="590" height="36"></canvas></div>
    </div>
</footer>
