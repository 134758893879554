import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  createUrlTreeFromSnapshot,
  Router
} from '@angular/router';
import { UserService } from './user/services/user.service';

export const accessRequestAuthGuard = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const service = inject(Router);
    if(window.location.pathname != "/login"){
        service.navigateByUrl("/login");
        return false;
    }
    return true;

};