export class qrzSubscriber{
    call: string = "";
    xref: string = "";
    aliases: string = "";
    dxcc: string = "";
    fname: string = "";
    name: string = "";
    addr1: string = "";
    addr2: string = "";
    state: string = "";
    zip: string = "";
    country: string = "";
    ccode: string = "";
    lat: string = "";
    lon: string = "";
    grid: string = "";
    county: string = "";
    fips: string = "";
    land: string = "";
    efdate: string = "";
    expdate: string = "";
    p_call: string = "";
    class: string = "";
    codes: string = "";
    qslmgr: string = "";
    email: string = "";
    url: string = "";
    u_view: string = "";
    bio: string = "";
    biodate: string = "";
    image: string = "";
    imageinfo: string = "";
    serial: string = "";
    moddate: string = "";
    MSA: string = "";
    AreaCode: string = "";
    TimeZone: string = "";
    GMTOffset: string = "";
    DST: string = "";
    eqsl: string = "";
    mqsl: string = "";
    cqzone: string = "";
    ituzone: string = "";
    born: string = "";
    user: string = "";
    lotw: string = "";
    iota: string = "";
    geoloc: string = "";
    attn: string = "";
    nickname: string = "";
    name_fmt: string = "";

}