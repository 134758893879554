import { Component, ViewChild } from '@angular/core';
import { UserService } from '../services/user.service';
import { LoggerService } from '../services/logger.service';
import { GroupInvitation } from '../groupInvitation.class';
import { ToastComponent } from '@syncfusion/ej2-angular-notifications';
import { stringToBoolean } from 'src/app/settings/globalVars';
import { GroupSerive } from '../services/groups.service';

@Component({
  selector: 'app-group-invitations',
  templateUrl: './group-invitations.component.html',
  styleUrl: './group-invitations.component.css'
})
export class GroupInvitationsComponent {

  invitations: GroupInvitation[] = [];

  @ViewChild('toastMessageBox') public toastMessageBox?: ToastComponent;
  public toastMessagePosition = { X: 'Right', Y: 'Top' };
  toastMessageContent: string = "";
  toastMessageTitle: string = "";
  showExpired: boolean = false;
  showAll: boolean = false;
  dateNow = Date.now();

  constructor(
    private usrsvc: UserService,
    private logsvc: LoggerService,
    private _groupSvc: GroupSerive,

  ) {}

  toast(title, content, colorClass = "") {
    $('#toast').removeClass('toastRed');
    $('#toast').removeClass('toastGreen');
    (this.toastMessageBox as ToastComponent).cssClass = colorClass;
    this.toastMessageTitle = title;
    this.toastMessageContent = content;
    (this.toastMessageBox as ToastComponent).show();
  }

  setStatus(accepted, expires) {
    let outStatus = "";
    if(accepted == null){
      outStatus = "PENDING";
    }
    if(this.parseDateTime(expires) < this.parseDateTime(this.dateNow)){
      outStatus = "EXPIRED";
    }
    if(accepted == true){
      outStatus = "ACCEPTED";
    }
    if(accepted == false){
      outStatus = "DECLINED";
    }
    return outStatus;
  }
  setAlertColor(accepted, expires) {
    let outStatus = "";
    if(accepted == null){
      outStatus = "alert-primary";
    }
    if(this.parseDateTime(expires) < this.parseDateTime(this.dateNow)){
      outStatus = "alert-danger";
    }
    if(accepted == true){
      outStatus = "alert-success";
    }
    if(accepted == false){
      outStatus = "alert-danger";
    }
    return outStatus;
  }

  refresh() {
    let getAll = sessionStorage.getItem('group-invitations-all');
    this.showAll = getAll == null ? false : stringToBoolean(getAll)
    let getExpired = sessionStorage.getItem('group-invitations-expired');
    this.showExpired = getExpired == null ? false : stringToBoolean(getExpired)
    this._groupSvc.getRecipiantInvites(this.logsvc.userId, this.showExpired, this.showAll).subscribe({
      next: (res) => {
        this.invitations = res;
      },
      error: (err) => {
        console.error(err);
      }
    });
  }
  actionInvite(action: string, data: GroupInvitation) {
    this._groupSvc.actionInvite(data, action).subscribe({
      next: (res) => {
        this.refresh();
        switch(action) {
          case "accept": 
            this.toast("Success!", "Group Invite has been accepted!", "toastGreen");
            break;
          case "decline":
            this.toast("Success!", "Group Invite has been declined!", "toastGreen");
            break;
        }
      },
      error: (err) => {
        console.error(err);
        this.toast("Failed!", "Failed to perform action on invitation.", "toastRed");

      }
    });
  }
  onCheckedChange() {
    sessionStorage.setItem('group-invitations-all', this.showAll.toString());
    sessionStorage.setItem('group-invitations-expired', this.showExpired.toString());
    this.refresh();
  }

  parseDateTime(jsonDate) {
    let dateObject = new Date(jsonDate != null ? jsonDate : "");
    return dateObject //.toLocaleString('en-US');
  }


  ngOnInit() {
    this.refresh();
  }
}
