<app-menu></app-menu>
<app-time-out-modal></app-time-out-modal>

<ejs-toast id="toast" #toastMessageBox [position]='toastMessagePosition'>
    <ng-template #title>
        <div>{{toastMessageTitle}}</div>
    </ng-template>
    <ng-template #content>
        <div>{{toastMessageContent}}</div>
    </ng-template>
</ejs-toast>

<h2 style="margin-left: 10px;">Add or Edit Net Presets</h2>
<table>
    <tr>
        <th></th>
        <th>Name</th>
        <th>Frequency</th>
        <th>Band</th>
        <th>Mode</th>
        <th>Day</th>
        <th>Start Time</th>
        <th>End Time</th>
    </tr>
    <ng-template ngFor let-x [ngForOf]="netPresets" let-i="index">
        <tr>
            <td>
                <button class="btn btn-danger delBtn" (click)="delete(x.id)">Delete</button>
            </td>
            <td>
                <ejs-textbox [(ngModel)]="x.name" placeholder="Net Name"></ejs-textbox>
            </td>
            <td>
                <ejs-textbox [(ngModel)]="x.frequency" placeholder="Frequency"></ejs-textbox>
            </td>
            <td>
                <ejs-dropdownlist id="text-box-band" [(ngModel)]="x.band" [dataSource]="bands" placeholder="Band"></ejs-dropdownlist>
            </td>
            <td>
                <ejs-dropdownlist class="dlmode" [(ngModel)]="x.mode" [dataSource]="modes" placeholder="Mode"></ejs-dropdownlist>
            </td>
            <td>
                 <ejs-multiselect #dowdl id='dowdl' [(value)]="x.dow" sortOrder="none" [dataSource]='daysOfTheWeek' mode='CheckBox' [showSelectAll]="true" [showDropDownIcon]="true" [showClearButton]="false" placeholder='Select days of the week'></ejs-multiselect>
            </td>
            <td>
                <ejs-timepicker [(ngModel)]="x.netTime" id='timepicker' placeholder="Time"></ejs-timepicker>
            </td>
            <td>
                <ejs-timepicker [(ngModel)]="x.netEndTime" id='timepicker' placeholder="Time"></ejs-timepicker>
            </td>
        </tr>
        <tr>
            <td colspan="100%" height="20px"></td>
        </tr>
    </ng-template>
</table>

<button class="btn btn-primary formBtn" (click)="addNetPreset()">Add</button>
<button class="btn btn-success formBtn" (click)="save()">Save</button>
