import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'w8zjt-calllog-client';


  ngOnInit() {
    console.log(`
%c#     #  #####  #######       # ####### 
#  #  # #     #      #        #    #    
#  #  # #     #     #         #    #    
#  #  #  #####     #          #    #    
#  #  # #     #   #     #     #    #    
#  #  # #     #  #      #     #    #    
 ## ##   #####  #######  #####     #    
`, 'background: black; color: #41FF00;');
  }
}
